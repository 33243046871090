import React, { useContext, useState, useEffect } from 'react';
import GlobalStateContext from "../../../../context";
import { Button, Icon, Modal, Tooltip, Popover } from 'antd';
import CreateStream from './CreateStream';
import { canUserCreateStream, remainingStreams, url } from '../../../../utils';
import { Subscribe } from "../../../../components/protected/Subscribe";
import { useHistory } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import UploadVideo from './UploadVideo';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const NewStreamButton = (props) => {
    const { child, popup ,children,block} = props
    const [state] = useContext(GlobalStateContext);
    const { features_accessible, streams } = state;
    const [showForm, setShowForm] = useState(false);
    const [canCreate, setCanCreate] = useState(false);
    const [type, setType] = useState(false);
    const [remainder, setRemainder] = useState(0);
    const history = useHistory()

    const handleCancel = () => {
        setShowForm(false);
    }

    const handleTypeSelect = (e) => {
        if (!canCreate) {
            return;
        }

        if (popup || !isMobile) {
            setType(e.key);
            setShowForm(true)

        } else {
            const ul = props.url || url("stream/create", true)
            history.push(ul);
        }
    }


    useEffect(() => {
        setCanCreate(canUserCreateStream(streams.length, features_accessible));
        setRemainder(remainingStreams(streams.length, features_accessible));
    }, [streams, features_accessible])
    useEffect(() => {
        // child = child || children
    }, [])


    return (
        <>
      
            {props.type === "card" ?

                <div className="event bg-white br-10 card-shadow"  {...props} onClick={handleTypeSelect} >
                    <div style={{ height: "100%", display: "flex", justifyContent: "center" }}>
                        {canCreate === true ?
                            <Button size="large" disabled={!canCreate} shape="circle" className="text-center align-center"
                                icon={'plus'} style={{ margin: "24% auto" }} onClick={handleTypeSelect}>
                            </Button>
                            :
                            <Popover
                                title="Not Allowed"
                                content={
                                    <>
                                        Sorry, your current plan does not allow you to create anymore events.
                                        <Subscribe />

                                    </>
                                }

                            >

                                <Button size="large" disabled={!canCreate} shape="circle"
                                    icon={'plus'} style={{ margin: "24% auto" }} onClick={handleTypeSelect}>
                                </Button>


                            </Popover>

                        }

                    </div>
                </div>

                :

                <div style={{ height: "100%", display: "flex", justifyContent: "center" }}>

                    {canCreate === true ?

                        <Button id="createStreamButton" className='btn btn-warning' block disabled={!canCreate}  {...props} onClick={handleTypeSelect}>
                            {children ? children : (<> Start one <Icon type="video-camera" /> </>)}
                        </Button> :
                        <Popover
                            title="Not Allowed"
                            content={
                                <>
                                    Allowed number of events exceeded.
                                    <div className='m-t-10'>

                                        <Subscribe />
                                    </div>

                                </>
                            }

                        >

                            <Button id="createStreamButton" block={block} disabled={!canCreate}  {...props} onClick={handleTypeSelect}>
                                {/* {child ? child : (<> Start one <Icon type="video-camera" /> </>)} */}
                                {children ? children : (<> Start one <Icon type="video-camera" /> </>)}
                            </Button>


                        </Popover>

                    }




                </div>

            }

            <Modal
                // title={`Launching a stream`}
                visible={showForm}
                onOk={handleCancel}
                onCancel={handleCancel}
                okButtonProps={false}
                footer={null}
            >
               { props.type=="upload" ? <UploadVideo /> : <CreateStream onClose={handleCancel} type={type} />}
            </Modal>
        </>
    )
}

NewStreamButton.defaultProps={
    block:true
}