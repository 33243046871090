import React, { useState, useContext, useEffect, createRef } from 'react';
import { Form, Button, Input, Avatar, Divider, Modal } from 'antd';
import GlobalStateContext from '../../../../context'
import Webservice from '../../../../classes/WebService';

import { _notification } from '../../../../utils';
import GetPlatformToken from '../../../../components/GetPlatformToken';

import DestinationWebservice from '../../../../classes/DestinationWebservice';




const AddDestination = function (props) {
  const [state] = useContext(GlobalStateContext);
  const { features_accessible, user } = state;
  const { getFieldDecorator, setFieldsValue } = props.form;
  const [destinations, setDestinations] = useState([]);
  const [token, setToken] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [destination, setDestination] = useState(false);
  const [showDestinationForm, setShowDestinationForm] = useState(false);
  // const [allow, setAllow] = useState(false);
  const [remainder, setRemainder] = useState(0);
  const formRef = createRef();
  const [provideKeyManually, setProvideKeyManually] = useState(true)

  const [savedJsonString, setJSStringSaved] = useState([])
  const [isLoadingFav, setIsLoadingFav] = useState(false)
  const onNewPlatformClick = () => {
    setShowDestinationForm(true)
  }
  const onDestFormClose = () => {
    setShowDestinationForm(false);
    setDestination(false);
    setLoading(false)
    props.callback && props.callback({})
  }




  useEffect(() => {


  }, [])


  const addButton = () => {

    return (
      <>

        <div className="">
          <Button id="addDestinationButton"
            onClick={onNewPlatformClick} icon="plus-circle"
            className=" text-blue-500" >
            Add Destination
            {/* <sub>
              <strong className={'badge badge-default'} dangerouslySetInnerHTML={{ __html: `${remainder} more` }} />
            </sub> */}
          </Button>
        </div>

      </>
    )
  }

  const fetchDestinations = async () => {
    try {
      const webservice = new Webservice();
      webservice.get({ endpoint: "streams/destinations" })
        .then(res => res.data)
        .then(dest => setDestinations(dest));
    } catch (error) {
      _notification.error("Failed to fetch destinations");
    }
  }
  const cleanUp = () => {
    onDestFormClose();
    props.form.resetFields();
    setToken("");
  }

  useEffect(() => {
    fetchDestinations();
    return cleanUp;
  }, []);



  const onSubmit = () => {
    // e.preventDefault();

    let service;
    props.form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        service = new DestinationWebservice();
        values = {
          label: values.destination.label,
          manual_entry: '1',
          platform: values.destination.type,
          data: {
            token: values.destination.token,
            url: values.destination.url
          }
        }
        await service
          .addDestination(values)
          .then(resp => {
            if (resp.status === 200) {

              props.form.resetFields();
              onDestFormClose();
              _notification.success("Destination added");
            } else {
              _notification.error(resp.message);
            }
          })
          .catch(err => {
            _notification.error(`Could not add destinaiton. ${err.message}`);
          });

        setLoading(false);

      }
    })
  }

  const destinationChanged = (val) => {
    let selected;

    for (let i = 0; i < destinations.length; i++) {
      if (destinations[i].id === val) {
        selected = destinations[i];
        setProvideKeyManually(!destinations[i].provider)
      }
    }
    console.log(selected)
    setDestination(selected);
    setFieldsValue({ 'destination[type]': selected.id })
  }
  const socialDestinationButtonCallback = (destObj) => {
    props.form.resetFields();
    _notification.success("Destination added");
    onDestFormClose();
    props.callback && props.callback(destObj)
  }

  const onProvideKeyManually = () => {
    setProvideKeyManually(!provideKeyManually);
  }

  const sst = {}

  return (
    <>

      {props.content ?

        <div style={{ "cursor": "pointer" }} onClick={onNewPlatformClick}>
          {props.content}
        </div>


        : addButton()}




      <Modal visible={showDestinationForm} className="" onOk={onSubmit} onCancel={onDestFormClose}>
        <Form ref={formRef} id="destinationForm" >
          <div className="platforms" >

            {

              destinations.map((dest) => {
                return (
                  <div key={dest.id} className={
                    ("platform card text-center")
                    + (destination.id === dest.id ? ' active' : '')
                  }
                    onClick={() => destinationChanged(dest.id)} >

                    <Avatar shape="square" className="channel"
                      size={destination.name ? 32 : 72}
                      key={dest.id}
                      src={dest.logo}
                      alt={dest.id}

                    />

                    <div className='label'> {dest.name}</div>

                  </div>
                )
              })

            }

          </div>
          <div hidden label="Destination">
            {getFieldDecorator('destination[type]', {
              rules: [{ required: true, message: 'Please select destination' }],
              validateTrigger: 'onBlur'
            })(
              <Input hidden />
            )}
          </div>

          <div className=""  >
            <div hidden={!destination.name}>
              <div hidden={destination.base_url} >
                <div className='my-2' >
                  <label
                    className="block text-gray-700 font-bold mb-2"
                    htmlFor="destination"
                  >
                    {`${destination.name} Stream Server`} 
                  </label>
                  {getFieldDecorator('destination[url]', {
                    initialValue: destination.base_url,
                    rules: [{ required: true, message: 'Please enter sever address' }],
                    validateTrigger: 'onBlur',


                  })(<Input type="url" placeholder={`Eg. rtmp://abc.com/live/app1`} style={sst} />)}
                </div>
              </div >
              {
                destination.note && <div className="alert alert-info" dangerouslySetInnerHTML={{ __html: destination.note }} />
              }
              <GetPlatformToken provider={destination.provider} type={destination.id} logo={destination.logo} callback={socialDestinationButtonCallback} />

              {/* <Button className="m-b-15" onClick={onProvideKeyManually} hidden={provideKeyManually}> Enter {destination.name} stream token. </Button> */}


              <div hidden={!provideKeyManually} label={`${destination.name} Stream Token`} >
                <div className='my-2'>
                <label
                    className="block text-gray-700 font-bold mb-2"
                    htmlFor="destination"
                  >
                    {destination.name} Stream Key
                  </label>
                  {
                    getFieldDecorator('destination[token]', {
                      rules: [{ required: true, message: 'Please enter stream key/token' }],
                      validateTrigger: 'onBlur'
                    })(<Input required placeholder={`Eg. 42356D454434323D`} style={sst} />)}
                </div>
              </div>
              <div hidden={!provideKeyManually} label={`${destination.name} Label`} >
                <div className='my-2'>
                <label
                    className="block text-gray-700 font-bold mb-2"
                    htmlFor="destination"
                  >
                     Label
                  </label>
                  {
                    getFieldDecorator('destination[label]', {
                      rules: [{ required: true, message: 'Please enter label/title ' }],
                      validateTrigger: 'onBlur'
                    })(<Input required placeholder={`Eg. Stream Server 1`} style={sst} />)}
                </div>
              </div>
              <div hidden={!provideKeyManually}
                style={{
                  width: '100%',

                  padding: '10px 16px',
                  // background: '#fff',
                  textAlign: 'center',
                }}
              >



              </div>
            </div>
          </ div>
        </Form>


      </Modal>


    </>
  )
}

export default Form.create()(AddDestination);