import React, { useState, useContext, useEffect, useRef } from 'react';
import { Form, Button, Input, InputNumber, Select, Tooltip, Icon, DatePicker, Switch, Collapse } from 'antd';
import GlobalStateContext from '../../../../context'
import TextArea from 'antd/lib/input/TextArea';
import StreamWebService from '../../../../classes/StreamWebService';
import { withRouter } from 'react-router-dom';
import { protectedRoutesPrefix } from '../../../../routers/routes';
import Rider from '../../../../components/Rider';
import FileUpload from '../../../../components/FileUpload';
import { _notification } from '../../../../utils';
import environments from '../../../../environments';
import { isMobile } from 'react-device-detect';

import { v4 as uuid } from 'uuid';
import { STREAM_SOURCE_FILE } from '../../../../constants';
import { Helmet } from 'react-helmet';

const UploadVideo = function (props) {

  const { onClose, type, item, onSave } = props;
  const { getFieldDecorator, setFieldsValue } = props.form;
  const [types, setTypes] = useState([]);
  const [vidoeUploadState, setVideoUploadState] = useState(false);
  const [imageUploadState, setImageUploadState] = useState(null);
  const [destinations, setDestinations] = useState([]);
  const [isPublic, setIsPublic] = useState(!!item ? item?.is_public : true);
  const [isLoading, setLoading] = useState(false);
  const [usdEquiv, setUSDEquiv] = useState(0.00);
  const [isDefaultCurreny, setisDefaultCurreny] = useState(true);
  const [resource_id, setResource_id] = useState(uuid());
  const [state, dispatch] = useContext(GlobalStateContext);
  const { eventCategories, configuration, features_accessible } = state;
  const targetRef = useRef(null);
  const { Option } = Select

  const catOptions = eventCategories.map(d => <Option key={d}>{d}</Option>);

  const fetchTypes = async () => {
    try {
      const webservice = new StreamWebService();
      webservice.getStreamsTypes()
        .then(res => res.data)
        .then(types => setTypes(types));
    } catch (error) {
      _notification.error("Failed to fetch types");
    }
  }

  const featchDestinations = async () => {
    try {
      const webservice = new StreamWebService();
      webservice.getDestinations()
        .then(res => res.data)
        .then(dest => setDestinations(dest));
    } catch (error) {
      _notification.error("Failed to fetch destinations");
    }
  }


  const cleanUp = () => {
    onClose && (onClose());
    props.form.resetFields()
  }

  useEffect(() => {
    fetchTypes();
    featchDestinations();
    // setisDefaultCurreny(state.currency.code == "USD")
    return cleanUp;
  }, [])

  useEffect(() => {
    setUSDEquiv((item?.ppv_value * state.xrate) || 0.00)
    setisDefaultCurreny(state.currency.code == "USD")
  }, [item])
  useEffect(() => {
    setisDefaultCurreny(state.currency.code == "USD")
  }, [state.currency])

  const onVideoFileUploadComplete = (info) => {
    setVideoUploadState(info);
    setFieldsValue({
      video: info.file.response.data.filename || info.file.response.data.key
    });
    _notification.success("Video uploaded.")
  }
  const onImageFileUploadComplete = (info) => {
    setImageUploadState(info);
    setFieldsValue({
      image: info.file.response.data.filename || info.file.response.data.key
    });
   
  }

  const onSubmit = (e) => {
    e.preventDefault();

    props.form.validateFields(async (err, values) => {

      if (!err) {

        values.resource_id = resource_id;
        values.type = type;
        values.user = state.user.id;
        values.source = STREAM_SOURCE_FILE;
        values.upload_data = { video: vidoeUploadState?.file?.response?.data, image: imageUploadState?.file?.response?.data }

        if (!isDefaultCurreny) {
          values.ppv_value = values.ppv_value ? parseFloat(values.ppv_value / state.xrate).toFixed(2) : 0.00;
        }
        setLoading(true);
        const service = new StreamWebService();
        await service
          .save({ data: values, id: item ? item?.id : null })
          .then(resp => {
            props.form.resetFields();
            onClose && (onClose());
            // _notification.success("Stream saved successfully");
            //Navigate to the details page
            const dt = resp.data

            if (onSave) {
              console.log("is save")
              return onSave(dt)
            }
            const path = `/${protectedRoutesPrefix}/events`
            console.log("The path ", path)
            props.history.push(path)
          })
          .catch(err => {
            const msg = err.response ? err.response.data.message : err.message
            _notification.error(`Could not save. ${msg}`);
          }).finally(_ => {
            setLoading(false);
          });


      }
    })
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  }, []);

  return (

    <>
      <div ref={targetRef}></div>
      <Rider steps={state.introSteps.createStreamForm} />
      <div id="createStreamForm" >
        <Form layout="vertical" hideRequiredMark  >
          <div className="">

            <div className="mb-3" id="streamBanner" >

              <Form.Item >
                {getFieldDecorator('video', {
                  rules: [],
                })(
                  <FileUpload
                    data={{ resource_id }}
                    preview={false}
                    showUploadList={true}
                    listType="text"
                    maxFileSize={1024}
                    // previewFile={item ? item.image : ""}
                    text={'Upload Video File'}
                    allowedTypes={['video/x-flv', 'video/mp4', 'video/x-msvideo', 'video/MP2T']}
                    onComplete={onVideoFileUploadComplete}
                  />
                )}
              </Form.Item>

            </div>
            {vidoeUploadState && <>
              <div className="mb-3" id="">
                <label className="block text-gray-700 font-bold mb-2" htmlFor="title">
                  Title
                </label>
                <Form.Item>
                  {getFieldDecorator('title', {
                    rules: [{ required: true, min: 6, message: 'Title is required and must be at least 6 characters' }],
                    initialValue: item ? item?.title : "",
                    validateTrigger: 'onBlur'
                  })(<Input placeholder="Wedding Day" />)}
                </Form.Item>
              </div>
              <div className="mb-3" id="streamDescription">
                <label className="block text-gray-700 font-bold mb-2" htmlFor="description">
                  Description
                </label>
                <Form.Item className="strong">
                  {getFieldDecorator('description', {
                    initialValue: item ? item?.description : "",
                    rules: [{ message: 'Please enter description' }],
                  })(<TextArea placeholder=" My wedding day to the world! " />)}
                </Form.Item>
              </div>

              <div className="mb-3" id="streamCategory">
                <label className="block text-gray-700 font-bold mb-2" htmlFor="category">
                  Category
                </label>
                <Form.Item >
                  {getFieldDecorator('category', {
                    initialValue: item?.category,
                    rules: [{ required: true, message: 'Specify category' }],
                    // initialValue:"",
                    validateTrigger: 'onBlur'
                  })(
                    <Select
                      // mode="tag"
                      size='large'
                      showSearch
                      placeholder="technology"
                      defaultActiveFirstOption={false}
                      showArrow={true}
                      filterOption={true}
                      // onSearch={this.handleSearch}
                      // onChange={this.handleChange}
                      notFoundContent={null}
                    >
                      {catOptions}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="mb-3" id="streamBanner">
                <label className="block text-gray-700 font-bold mb-2" htmlFor="image">
                  Event Thumbnail
                </label>
                <Form.Item>
                  {getFieldDecorator('image', {
                    rules: [],
                  })(
                    <FileUpload
                      data={{ resource_id }}
                      previewFile={item ? item?.image : ""}
                      text={'Event Image'}
                      onComplete={onImageFileUploadComplete}
                    />
                  )}
                </Form.Item>

              </div>
              <div className="mb-3 flex flex-row" id="streamVisibility">
                <label className="block text-gray-700 font-bold mr-2" htmlFor="video">
                  Public
                  </label>
                <Form.Item>
                  {getFieldDecorator('is_public', {
                    initialValue: isPublic
                  })(

                    <Switch
                      checkedChildren={<><Icon type="check" /> Yes</>}
                      unCheckedChildren={<><Icon type="close" /> No</>}
                      defaultChecked={isPublic}
                      onChange={_ => setIsPublic(_)}
                    />

                  )}
                </Form.Item>
           
              </div>
              <Collapse bordered={false} >
                <Collapse.Panel header="Paywall">
                  <div className="row">
                    {configuration.enable_ppv === '1' &&
                      <Form.Item help={`Amount you expect viewers to pay before watching your event.
                          ${!isDefaultCurreny ?
                          `Please note that this amount will be converted to USD at a rate of ${state.currency.symbol} ${state.xrate}` : ""

                        }
                          `}
                      >
                        {getFieldDecorator('ppv_value', {
                          rulses: [{ min: 0.00, message: 'Amount cannot be lower than 0.00' }],

                          initialValue: !!item ? (!!item && item?.ppv_value * state.xrate) : 0.00,
                          validateTrigger: 'onBlur'
                        })(<InputNumber min={0.00} placeholder={`${state.currency.symbol} 2.00`} onChange={valu => setUSDEquiv(parseFloat(valu / state.xrate).toFixed(2))} />)}
                        {
                          state.currency.code != "USD"
                          &&
                          <> &cong; <InputNumber disabled value={`$ ${usdEquiv} `} /></>
                        }
                      </Form.Item>
                    }


                  </div>

                </Collapse.Panel>
              </Collapse>
              <div className="m-t-20">

                <Button block={isMobile} loading={isLoading} key="submit_button" disabled={isLoading} type="warning" onClick={onSubmit}>
                  Proceed
                </Button>
              </div>
            </>}
          </div>
        </Form>
      </div>
    </>
  )
}

export default withRouter(Form.create()(UploadVideo));