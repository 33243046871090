import { Avatar, Button, Card, Icon, message, Modal } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React, { useContext, useEffect, useState } from 'react';
import DasbboardWebservice from '../classes/DashboardWebservice';
import LoadingCards from './LoadingCards';
import calendarImage from '../assets/images/calendar_circle.png'
import uploadImage from '../assets/images/cloud-computing.png'
import webacmImage from '../assets/images/video-camera.png'
import videoCall from '../assets/images/meeting.png'
import { NewStreamButton } from '../pages/protected/streams/components/NewStreamButton';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { protectedRoutesPrefix } from '../routers/routes';
import { canIUse, url, canUserCreateStream, remainingStreams } from '../utils';
import GlobalStateContext from '../context';
import { STREAM_SOURCE_ENCODER, STREAM_SOURCE_STUDIO } from '../constants';
import { CalendarPlus2, CloudUpload, Unplug, Video, Webcam } from 'lucide-react';
import SubscribePopup from './SubscribePopup';
import CreateStream from '../pages/protected/streams/components/CreateStream';
import UploadVideo from '../pages/protected/streams/components/UploadVideo';




// type Props = {
//     onClick: Function,
//     description: String,
//     title: String,
//     image: String | Object,
//     children: ReactDOM,
//     disable: Boolean,
//     selected: Boolean,
//     className?:string

// }

export function FeatureCard(props) {

    const clickableCllass = props.disable ? "inactive" : props.onClick ? " clickable" : "";
    const [showPrompt, setShowPrompt] = useState(false)

    return <>
        {/* <Card onClick={!props.disable && props.onClick} style={{ width: "auto" }} className={`feature-card  ${clickableCllass}  ${props.selected && 'selected'}`} actions={[]} >

            <Meta avatar={<Avatar size={!isMobile ? 100 : 60} src={props.image || ""} />}
                title={props.title || "Title"}
                description={props.description || "Description"} />
            {props.children}

        </Card> */}

        <div
            className={` ${props.selected ? 'border-2' : 'border'} ${props.className} ${props.disable ? "cursor-not-allowed opacity-50 disabled" : 'cursor-pointer'}
        w-62    md:w-96 bg-white  flex flex-column m-2 rounded-lg  transition-all duration-200 transform 
         hover:shadow-lg hover:-translate-y-1 px-2 py-3  
         
          shadow-xs bg-white  border-gray-100 hover:shadow-sm transition-shadow`}
            onClick={!props.disable ? props.onClick : () => {setShowPrompt(true)  }}
        >
            <div className='grid gap-2 grid-cols-4 w-full  h-ful'>
                {props.icon && props.icon}
                {props.image && <div className='bg-gray-200 my-auto mx-auto rounded-full w-3/12'>
                    <img className='h-full w-full' src={props.image || ""} />

                </div>
                }
                <div className='p-2 text-left col-span-3 '>
                    <h3 className='text-lg text-left mt-2 '> {props.title || "Title"}</h3>
                    <div className=''>{props.description || "Description"}</div>
                </div>
            </div>
            {props.children && <div className='h-10 mx-auto bottom-0  w-full '>{props.children}</div>
            }
        </div>
       {showPrompt && <SubscribePopup onClose={_=>setShowPrompt(false)} visible={showPrompt}/>}
    </>
}


export default function DashboardActions() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const history = useHistory();
    const [state, dispatch] = useContext(GlobalStateContext)
    const { features_accessible, app, streams } = state;

    const [canCreate, setCanCreate] = useState();
    const [canRTMP, setCanRTMP] = useState();
    const [canUpload, setCanUpload] = useState();
    const [canInstantStream, setCanInstantStream] = useState();
    const [canUseStudio, setCanUseStudio] = useState();
    const [showInstantModal, setShowInstantModal] = useState(false);
    const [showCreateStreamModal, setShowCreateModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);

    function fetchStats() {
        const wbs = new DasbboardWebservice();
        setLoading(true);
        wbs.fetchDasboardData().then(data => {
            setData(data.data);
        }).finally(e => {
            setLoading(false);
        }).catch(err => {
            message.error("Unable to fetch dashboard data. " + err.message);
        });
    }

    useEffect(() => {
        setCanCreate(canUserCreateStream(streams.length, features_accessible));
        setCanInstantStream(canIUse('primary_studio', features_accessible))
        setCanRTMP(canIUse('custom_rtmp_input', features_accessible))
        setCanUpload(canIUse('file', features_accessible))
        setCanUseStudio(canIUse('webcam', features_accessible))
        // setRemainder(remainingStreams(streams.length, features_accessible));
    }, [streams, features_accessible])
    useEffect(() => {
        // child = child || children
    }, [])

    function buildStatCards() {
        const [events, followers, revenue, views] = [data.events, data.followers, data.revenue, data.views]
        return <div className='flex flex-wrap space-x-4 justify-start'>
            <FeatureCard
                className={"bg-blue-500"}
                onClick={_ => setShowInstantModal(true)}
                //  disable={!(canInstantStream)} 
                description={
                    <div className='font-light'>
                        Go live instantly with you device or connect a software.
                    </div>
                } title={"Start Stream"}
                icon={<Video className='mx-auto my-auto w-16 h-16 bg-blue-100 text-blue-500 rounded-full p-3' />} >

                {/* <div className='grid grid-cols-2 gap-2 text-sm' > */}
                {/* <div className='instant-stream-actions '> */}

                {/* <Button className=' instant-stream-actions-button' block disabled={!canIUse('primary_studio', features_accessible)} type='default' onClick={_ => canIUse('primary_studio', features_accessible) && history.push(`/instant?s=${STREAM_SOURCE_STUDIO}`)} >From Studio</Button>
                        <Button className='instant-stream-actions-button'block  disabled={!canIUse('custom_rtmp_input', features_accessible)} type='default' onClick={_ => canIUse('custom_rtmp_input', features_accessible) && history.push(`/instant?s=${STREAM_SOURCE_ENCODER}`)} >From Encoder</Button>
                     */}

                {/*                    
                        <button
                            disabled={!(canInstantStream && canUseStudio)}
                            className={`disabled:cursor-not-allowed py-2 px-4 bg-gray-100 text-gray-600 rounded hover:bg-gray-100 transition-colors`}
                            onClick={_ =>  history.push(`/instant?s=${STREAM_SOURCE_STUDIO}`)}
                        >From Studio</button>
                    
                        <button
                        
                            disabled={!(canInstantStream && canRTMP)}
                            className={` disabled:cursor-not-allowed  py-2 px-4 bg-gray-100 text-gray-600 rounded hover:bg-gray-100 transition-colors `}
                            onClick={_ => history.push(`/instant?s=${STREAM_SOURCE_ENCODER}`)}
                        >From Encoder</button> */}


                {/* </div> */}
                {/* </div> */}
            </FeatureCard>

            <FeatureCard
                disable={!(canCreate)}
                onClick={_ =>{ 
                    !isMobile? setShowCreateModal(true) 
                    : history.push(url("stream/create", true))
                    }}
                description={
                    <div className='font-light'>
                        Create or schedule an event. Go live right after or later.
                    </div>
                } title={"Schedule Event"}

                icon={<CalendarPlus2 className='mx-auto my-auto w-16 h-16 bg-blue-100 text-blue-500 rounded-full p-3' />}
            >
                {/* <div className='flex h-full  rounded'>
               
                    
                        <button
                            disabled={!(canCreate)}
                            className={`disabled:cursor-not-allowed  w-full py-2 px-4 bg-gray-100 text-gray-600 rounded hover:bg-gray-100 transition-colors`}
                            onClick={_ =>  history.push(url("stream/create", true))}
                        > Create Event</button>
                    
                </div> */}


            </FeatureCard>
            <FeatureCard
                disable={!(canCreate && canUpload)}
                onClick={_ => {
                    !isMobile? setShowUploadModal(true) :
                    history.push(url(`${protectedRoutesPrefix}/stream/upload`, false))
                }}
                description={
                    <div className='font-light'>
                        Upload pre-recorded video of an event.
                    </div>
                } title={"Upload Video"}
                icon={<CloudUpload className='mx-auto my-auto w-16 h-16 bg-blue-100 text-blue-500 rounded-full p-3' />}
            >

                {/* <div className='flex h-full rounded'>


                        <button
                            disabled={!(canCreate && canUpload)}
                            className={`disabled:cursor-not-allowed  w-full py-2 px-4 bg-gray-100 text-gray-600 rounded hover:bg-gray-100 transition-colors`}
                           
                        > Upload </button>
                
                </div> */}

            </FeatureCard>
            {/* <FeatureCard description={
                "Start a meeting, share links for participants to join "
            } title="Start an Instant Meeting" image={videoCall} >
                <Button type='dark' onClick={_=> history.push(`${protectedRoutesPrefix}/meet`) } >Start Meeting</Button>
            </FeatureCard> */}

        </div>
        // return data.map(dt => <StatCard/>)
    };

    useEffect(() => {
        fetchStats()
    }, [])


    return (
        <div>
            {
                loading ? <LoadingCards number={5} alignment="row" /> :
                    buildStatCards()
            }

            <Modal
                footer={false}
                closable={false}
                className='flex'
                onCancel={_ => setShowInstantModal(false)} visible={showInstantModal}>
                    
                <div className='mx-auto'>
                <div className='m-2'>
                    <h2 className='text-lg'>How would you like to stream?</h2>
                </div>


                    <FeatureCard
                        disable={!(canInstantStream)}
                        onClick={_ =>  history.push(`/instant?s=${STREAM_SOURCE_STUDIO}`)}
                        description="Go live with a webcam or any connected device"
                        title={"Viblica Studio"}

                        icon={<Webcam className='mx-auto my-auto w-16 h-16 bg-red-100 text-red-500 rounded-full p-3' />}
                    >
                        {/* <div className='flex h-full  rounded'>
               
                    
                        <button
                            disabled={!(canCreate)}
                            className={`disabled:cursor-not-allowed  w-full py-2 px-4 bg-gray-100 text-gray-600 rounded hover:bg-gray-100 transition-colors`}
                            onClick={_ =>  history.push(url("stream/create", true))}
                        > Create Event</button>
                    
                </div> */}
                        
                    </FeatureCard>
                    <FeatureCard
                        disable={!(canInstantStream)}
                        onClick={_ => history.push(`/instant?s=${STREAM_SOURCE_ENCODER}`)}
                        title={"Connect OBS,Vmix,etc"}
                        description="Connect to softwares like OBS,VMix, Zoom"
                        icon={<Unplug className='mx-auto my-auto w-16 h-16 bg-red-100 text-red-500 rounded-full p-3' />}
                    >
                        {/* <div className='flex h-full  rounded'>
               
                    
                        <button
                            disabled={!(canCreate)}
                            className={`disabled:cursor-not-allowed  w-full py-2 px-4 bg-gray-100 text-gray-600 rounded hover:bg-gray-100 transition-colors`}
                            onClick={_ =>  history.push(url("stream/create", true))}
                        > Create Event</button>
                    
                </div> */}


                    </FeatureCard>
                </div>
            </Modal>
            <Modal
            centered
                footer={false}
                closable={false}
                onCancel={_ => setShowCreateModal(false)} 
                visible={showCreateStreamModal}>
            
                    <CreateStream/>
          
            </Modal>
            <Modal
            centered
                footer={false}
                closable={false}
                onCancel={_ => setShowUploadModal(false)} 
                visible={showUploadModal}>
            
                    <UploadVideo/>
          
            </Modal>
        </div>
    )
}



