import React from 'react';
import { Upload, Icon, message } from 'antd';
import environments from '../environments';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}


export default class FileUpload extends React.Component {
  state = {
    loading: false,
    disable: false
  };

  constructor() {
    super()
    this.beforeUpload = this.beforeUpload.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  beforeUpload = (file) => {
    const { allowedTypes, maxFileSize } = this.props;

    const isAllowed = allowedTypes.indexOf(file.type) !== -1

    if (!isAllowed) {
      message.error('File type not allowed!. Supported formats ' + allowedTypes.join(','));
    }
    const maxSize = (file.size / 1024 / 1024) < maxFileSize;
    if (!maxSize) {
      message.error(`File size must be ${maxFileSize}mb or less `);
    }
    if (isAllowed && maxSize) {
      this.props.beforeUpload(file)
      return true;
    }
    return false;
  }

  onRemove = (info)=>{
    console.log("On remove",info)
  }
  handleChange = info => {

    console.log("On Change",info);
    const uploadElement = document.querySelector( ".ant-upload.ant-upload-select")
    // this.setState({ disable: !this.props.preview && (info?.fileList.length > 0 ) })

    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {

      // Get this url from response in real world.
      if (this.props.preview == true) {

        getBase64(info.file.originFileObj, imageUrl => {

          this.setState({
            imageUrl,
            loading: false

          })
        })
      } else {
        this.setState({
          loading: false,
        });

      };


      this.props.onComplete(info);
     if(uploadElement && !this.props.preview){

       uploadElement.style.display="none"
     }
    }
    if (info.file.status === "removed" && uploadElement) {

        uploadElement.style.display="flex"
      return;
    }
  };


  render() {
    const { text, width, height, children } = this.props;
    const uploadButton = (
      <div style={{textAlign:"center"}}>
        {children ?
          <>
          {this.state.loading? <Icon style={{ fontSize: "3rem" }} type={'loading'} />  :children}
          </>:
          <div>
            <Icon style={{ fontSize: "3rem" }} type={this.state.loading ? 'loading' : 'cloud-upload'} />
            <div className="ant-upload-text"> {text || "Upload Image"}</div>
            <div className="">
              <small>
                Supported type:   {
                  this.props.allowedTypes.map(tp => {
                    const splt = tp.split('/');
                    return <span key={tp}> {splt[splt.length - 1]}</span>
                  }
                  )
                }
              </small>
            </div>
          </div>
        }
      </div>
    );
    const { imageUrl } = this.state;
    const endpoint = `${environments.app.api.endpoint}/misc/upload`
    return (
      <div className='border border-dashed'>

      <Upload

        data={this.props.data}
        accept={this.props.allowedTypes.join(",")}
        onRemove={this.onRemove}
        name="file"
        listType={this.props.listType ?? "picture-card"}
        className={this.props.className ?? `avatar-uploader ${this.state.disable ? 'disable' : ''} w-full`}
        showUploadList={this.props.showUploadList}
        action={endpoint}
        beforeUpload={this.beforeUpload}
        onChange={this.props.handleChange || this.handleChange}
        customRequest={this.props.customRequest}
        multiple={false}
      // disabled={this.state.disable}
      >
        {imageUrl && !children && this.props.preview?
          <div alt="avatar" style={{ backgroundImage: `url(${imageUrl})`, height, backgroundPosition: "center" }} /> : uploadButton}
      </Upload>
      </div>
    );
  }
}



FileUpload.defaultProps = {
  allowedTypes: ['image/jpeg', 'image/png'],
  maxFileSize: 10,
  showUploadList: false,
  width: "100%",
  height: "200px",
  onComplete: _ => _,
  beforeUpload: _ => _,
  preview:true,
  data:{},
  text:"Upload Image"


}
