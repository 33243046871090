import React from 'react'
import { Link, Route, useHistory, useLocation } from 'react-router-dom';
import { Menu, Icon, message, notification, } from "antd";
import { protectedRoutes, protectedRoutesPrefix, routes } from './routers/routes';
import moment from 'moment'
import axios from 'axios'
// import  beaver from 'beaver-logger';
import environments from './environments';
import { isEqual, isInteger, uniqBy } from 'lodash';
import Webservice from './classes/WebService';
import { Avatar } from '@material-ui/core';

let beaver = require('beaver-logger');



export const logger = beaver.Logger({

    url: environments.app.api.logger,
    // Prefix to prepend to all events
    prefix: environments.app.name,

    // Log level to display in the browser console
    logLevel: beaver.LOG_LEVEL.WARN,

    // Interval to flush logs to server
    // flushInterval: 60 * 1000
});

const { SubMenu } = Menu;

// export const  socketio = SocketIOClient(environments.app.api.socket);

export const navigateTo = (path) => {
    window.location.replace(path)
}

export const snakecase = (text) => {
    return text.toLowerCase().trim().replace(" ", "_").replace(/\s/g, "")
}
export function titleCase(string) {
    var sentence = string.toLowerCase().replace(/_/g, " ").split(" ");
    for (var i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(" ");
}


export const buildMenuItems = (items, parent = '') => {

    return items.map(item => {
        if (item.visible !== false) {
            if (!item.children || item.children.length < 1) {
                const pth = parent !== '' ? `${parent.path}${item.path}` : item.path;
                return (
                    <Menu.Item key={pth}  >
                        <Link to={pth}>
                            <Icon type={item.icon} />
                            <span>
                                {item.name}
                            </span>
                        </Link>
                    </Menu.Item>
                )
            } else {

                return (

                    <SubMenu
                        key={item.path}
                        title={
                            <span>
                                <Icon type={item.icon} />
                                <span> {item.name}</span>
                            </span>
                        }
                    >
                        {buildMenuItems(item.children, item)}
                    </SubMenu>
                )
            }
        }
    })
}



export const buildRoutesItems = (items, parent = '') => {

    return items.map(item => {

        if (!item.children || item.children.length < 1) {

            return (
                <Route name={item.name} exact={item.exact} key={item.name} path={parent ? `${parent.path}${item.path}` : item.path}>
                    {item.component}
                </Route>

            )
        } else {

            return (

                buildRoutesItems(item.children, item)
            )
        }

    })
}


export const prepareURL = (url) => {
    //@todo Implement a sanitizer
    return url;
}
export const convertToLocale = (amount) => {
    //@todo Implement a sanitizer
    return amount;
}



/**
 * 
 */
export const authToken = () => {
    return localStorage.getItem("token")
}


export const sanitizePhoneNumber = (string) => {
    return parseInt(string)
}


export const abbreviator = (text) => {
    const splits = text.split("", text);
    const initials = splits.map((word) => word.substring(0, 1));
    return initials.toString();
}

/**
 * 
 * @param {Array} valMessages 
 */
export const processValidationErrors = (errors) => {

    if (!!errors) {
        for (let i = 0; i < errors.length; i++) {
            //Get input  the field name
            const err = errors[i];
            console.log(err.field)
            const elem = document.getElementById(err.field);
            if (elem) {

                const parentFormControl = elem.closest('.ant-form-item-control')
                const messageElem = document.createElement('div')
                messageElem.className = "ant-form-explain";
                messageElem.innerHTML = err.messages[0];
                parentFormControl.classList.toggle('has-error')
                parentFormControl.append(messageElem);
            } else {

            }

        }

    }

}


export const clearErrors = () => {
    const elems = document.querySelector(".ant-form-explain")

    if (!!elems) {
        elems.remove()
    }

}

export const generateVideoLink = (token, type) => {

}

/**
 * 
 */

export function generateID(prefix = "") {
    let sessionID = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWKYZ0123456789';
    const numPossible = possible.length;
    const timestamp = (new Date()).toTimeString();
    for (let i = 0; i < 8; i++) {
        sessionID += possible.charAt((Math.random() * numPossible) | 0);
    }
    return `${prefix}${timestamp}${sessionID}`;
}



/** 
 * @param {number} currentDestinationsCount 
 * @param {object} features_accessible
 */
export const canUseCamera = (features_accessible) => {
    var rs = false;
    try {
        const { webcam } = features_accessible;
        rs = webcam || false;
    } catch (error) {
        _notification.error({ message: "Forgive! something went wrong so we couldn't tell if you can add more destinations" })
        logger.error("feature_accessibility", { features_accessible, error })
    }
    finally {
        return rs
    }
}

/** 
 * @param {number} currentDestinationsCount 
 * @param {object} features_accessible
 */
export const canUseFile = (features_accessible) => {
    var rs = false;
    try {
        const { file } = features_accessible;
        rs = file || false;
    } catch (error) {
        _notification.error({ message: "Forgive! something went wrong so we couldn't tell if you can add more destinations" })
        logger.error("feature_accessibility", { features_accessible, error })
    }
    finally {
        return rs
    }
}

/**
 * @param {number} currentDestinationsCount
 * @param {object} features_accessible
 */
export const canIUse = (key, features_accessible) => {
    var rs = false;
    try {
        const isAccessible = features_accessible[key];
        rs = isAccessible || false;
    } catch (error) {
        _notification.error({ message: "Forgive! something went wrong so we couldn't tell if you can add more destinations" })
        logger.error("feature_accessibility", { features_accessible, error })
    }
    finally {
        return rs
    }
}

/**
 * @param {number} currentDestinationsCount
 * @param {object} features_accessible
 */
export const canUseScreenRecording = (features_accessible) => {
    var rs = false;
    try {
        const { screen_recording } = features_accessible;
        rs = screen_recording || false;
    } catch (error) {
        _notification.error({ message: "Forgive! something went wrong so we couldn't tell if you can add more destinations" })
        logger.error("feature_accessibility", { features_accessible, error })
    }
    finally {
        return rs
    }
}
/**
 * @param {number} currentDestinationsCount
 * @param {Object} features_accessible
 */
export const canUserAddPlatform = (currentDestinationsCount, features_accessible) => {
    var rs = false;
    try {
        const alreadyCreated = currentDestinationsCount; //get the current number of already created stream records
        const { max_destinations } = features_accessible
        rs = max_destinations === -1 ? true : alreadyCreated < max_destinations;
    } catch (error) {
        _notification.error({ message: "Forgive! something went wrong so we couldn't tell if you can add more destinations" })
        logger.error("feature_accessibility", { currentDestinationsCount, features_accessible, error })
    }
    finally {
        return rs
    }
}


/**
 * @param {number} currentStreamsCount
 * @param {array} features_accessible
 */
export const canUserCreateStream = (currentStreamsCount, features_accessible) => {
    var rs = false;
    try {
        const alreadyCreated = currentStreamsCount;
        const { max_stream_records } = features_accessible
        rs = max_stream_records === -1 ? true : alreadyCreated < max_stream_records;
    } catch (error) {
        _notification.error({ message: "Forgive! something went wrong so we couldn't tell if you can add more streams" });
        logger.error("feature_accessibility", { currentStreamsCount, features_accessible, error })
    } finally {
        return rs
    }

};

/**
 * @param {number} currentStreamsCount
 * @param {array} features_accessible
 */
export const canUserEmbedStream = (features_accessible) => {
    var rs = false;
    try {
        rs = features_accessible.embed == true
    } catch (error) {
        _notification.error({ message: "Forgive! something went wrong so we couldn't tell if you can embed streams" });
        logger.error("feature_accessibility", { features_accessible, error })
    } finally {
        return rs
    }

};

/**
 * 
 * @param {String} path 
 * @param {Boolean} type 
 */
export const url = (path, isProtected = false) => {
    if (isProtected) {
        const fullPath = `/${protectedRoutesPrefix}/${path}`;
        return fullPath.replace(/\/$/, "") // remove trailing slash
    }
    return (`/${path}`).replace(/\/$/, "");// remove trailing slash
}


export const humanDate = (dateString) => {
    return moment(dateString).format("MMM Do YYYY")
}

/**
 * 
 */
export async function ipLookUp(ip = "") {
    try {
        const { api } = environments.app;
        const endpoint = `${api.endpoint}/misc/locationinfo`;
        return await axios.get(endpoint, { params: { ipAddress: ip } })
            .then(response => response.data);
    } catch (err) {
        logger.error("ip_lookup", { err })

    }
}


export function getMetaValue(metaName) {
    const metas = document.getElementsByTagName('meta');
    for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === metaName) {
            return metas[i].getAttribute('content');
        }
    }

    return '';
}

export const stringInitials = (string) => {
    const splits = string.split(" ");
    return splits.map(word => word[0]).toString().replace(",", " ");

}



export const _notification = {

    success: (message) => notification.success({ message, placement: "bottomLeft" }),
    info: (message) => notification.info({ message, placement: "bottomLeft" }),
    warning: (message) => notification.warning({ message, placement: "bottomLeft" }),
    error: (message) => notification.error({ message, placement: "bottomLeft" }),

}

/**
 * Gets the remaining figures of a feature
 * @param {int} what
 * @param {object} user
 */
export const remainingStreams = (currentStreamsCount, features_accessible) => {
    var rs = 0;
    try {
        const alreadyCreated = currentStreamsCount;
        const { max_stream_records } = features_accessible
        rs = max_stream_records === -1 ? "&#8734;" : max_stream_records - alreadyCreated; //-1 is unlimited
    } catch (error) {
        _notification.error({ message: "Forgive! something went wrong so we couldn't tell if you can add more streams" });
        logger.error("feature_accessibility", { currentStreamsCount, features_accessible, error })
    } finally {
        return rs;
    }
}/**
 * Gets the remaining figures of a feature
 * @param {int} what
 * @param {object} user
 */
export const remainingDestinations = (currentDestinationsCount, features_accessible) => {
    var rs = 0;
    try {
        const alreadyCreated = currentDestinationsCount;
        const { max_destinations } = features_accessible
        rs = max_destinations === -1 ? "&#8734;" : max_destinations - alreadyCreated; //-1 is unlimited
    } catch (error) {
        _notification.error({ message: "Forgive! something went wrong so we couldn't tell if you can add more streams" });
        logger.error("feature_accessibility", { currentDestinationsCount, features_accessible, error });
    } finally {
        return rs;
    }
}

/**
 * Capitalizes the first character of a string
 * @param {String} text 
 */
export const ucFirst = (text) => text && text.charAt(0).toUpperCase() + text.slice(1);


/**
 * Limits text to specific limit with ellipsis attached
 * @param {String} text 
 * @param {Number} limit 
 */
export const ellipsis = (text, limit = 20) => {
    return text.length > limit ? text.substring(0, limit - 1) + "..." : text
}



/**
 * Scales dimension up or down to fit
 * @param {object} baseConstraint 
 * @param {object newConstraints 
 */

export const scaleToFit = (baseConstraint, newConstraints) => {

    // rs > ri ? (wi * hs/hi, hs) : (ws, hi * ws/wi)
    let newWidth = 0
    let newHeight = 0;
    let displacement;

    let ws = baseConstraint.width
    let hs = baseConstraint.height

    let wi = newConstraints.width
    let hi = newConstraints.height

    var rs = ws / hs
    var ri = wi / hi



    if (rs > ri) {
        newWidth = (wi * hs / hi)
        newHeight = hs

    }
    else if (rs === ri) {

        //let pick the smallest height
        newHeight = hs
        newWidth = ws
    }

    else {
        newWidth = ws
        newHeight = (hi * ws / wi)
    }


    displacement = {
        width: baseConstraint.width - newWidth,
        height: baseConstraint.height - newHeight
    }


    return { width: Math.floor(newWidth), height: Math.floor(newHeight), displacement }

}
export function getVideoStreamSettings(streamObject, t) {
    return streamObject.getVideoTracks()[0].getSettings()
}

export function useQuery() {
    return new URLSearchParams(useLocation().search)
}




export function secToHMS(SECONDS) {
    return new Date(SECONDS * 1000).toISOString().substr(11, 8);
}


export function timeSince(seconds) {



    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " yrs";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " mo";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hrs";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " min";
    }
    return Math.floor(seconds) + " sec";
}

export const getRoute = (routeId) => {
    const all = (protectedRoutes.concat(routes));
    return all.find(rut => {
        return rut.id === routeId

    })
}


export const buildCategory = (stream) => {
    const cat = stream.category;
    return typeof cat === String ?
        <span className="badge badge-info">{cat || "N/A"}</span> :
        stream.category.map(ct => <span key={ct} className="badge badge-info m-r-5">{ct}</span>)
}
export const buildCategoryHashtags = (stream) => {
    const cat = stream?.category;
    return typeof cat === String ?
        <span className="hashtag badge badge-info">{cat}</span> :
        <span className="hashtag ">#{stream.category.join("#")}</span>
}


export const apprioprateVal = function (dataType, value) {
    if (isInteger(value)) {

        switch (dataType) {
            case "timestamp":
                value = timeSince(value / 1000);
                break;

            default:

                break;


        }
    }
    return value
}


export function pushNotificationPrompt(user = {}) {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
        console.log('Service Worker and Push is supported');
        let swFilePath = process.env.PUBLIC_URL + '/sw.js';
        if (process.env.NODE_ENV === 'production') {
            swFilePath = process.env.PUBLIC_URL + '/service-worker.js'
        }

        navigator.serviceWorker.register(swFilePath)
            .then(function (swReg) {
                console.log('Service Worker is registered', swReg);

                return swReg.pushManager.getSubscription().then(function (subscription) {
                    let shoulUpdate = true;
                    if (!subscription) {
                        return swReg.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: detiremineAppServerKey()
                        });
                    }

                    if (shoulUpdate) {
                        const usrwbs = new Webservice();
                        let subs = [...user.push || [], subscription.toJSON()];
                        if (subs.length > 1) {
                            subs = uniqBy(subs, 'endpoint')
                        }

                        usrwbs.put({ endpoint: 'auth/profile', data: { push: subs } })
                    }

                    return subscription;
                })
                //   swRegistration = swReg;
            })
            .catch(function (error) {
                console.error('Service Worker Error', error);
            });
    } else {
        console.warn('Push messaging is not supported');
        // pushButton.textContent = 'Push Not Supported';
    }
}

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export function detiremineAppServerKey() {
    const vapidPublicKey = 'BJTBXnKgLYZbuQ1ngUMTaxtwTSi4kI7xN2eYXslfpeMlnwJQp6LTLi1quVyNeER0-GHjoeTLz1vXllulkR2mMGE';
    return urlBase64ToUint8Array(vapidPublicKey);

}


export function getBrowserInnerDimensions() {
    var myWidth = 0, myHeight = 0;
    if (typeof (window.innerWidth) == 'number') {
        //Non-IE
        myWidth = window.innerWidth;
        myHeight = window.innerHeight;
    } else if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
        //IE 6+ in 'standards compliant mode'
        myWidth = document.documentElement.clientWidth;
        myHeight = document.documentElement.clientHeight;
    } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
        //IE 4 compatible
        myWidth = document.body.clientWidth;
        myHeight = document.body.clientHeight;
    }

    return {
        height: myHeight,
        width: myWidth
    }

}
export function getBrowserOuterDimensions() {
    var myWidth = 0, myHeight = 0;
    if (typeof (window.outerWidth) == 'number') {
        //Non-IE
        myWidth = window.outerWidth;
        myHeight = window.outerHeight;
    } else if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
        //IE 6+ in 'standards compliant mode'
        myWidth = document.documentElement.clientWidth;
        myHeight = document.documentElement.clientHeight;
    } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
        //IE 4 compatible
        myWidth = document.body.clientWidth;
        myHeight = document.body.clientHeight;
    }

    return {
        height: myHeight,
        width: myWidth
    }

}



export function getCrop(video, { width, height }) {
    const aspectRatio = width / height;

    let newWidth;
    let newHeight;

    const videoRatio = video.videoWidth / video.videoHeight;

    if (aspectRatio >= videoRatio) {
        newWidth = video.videoWidth;
        newHeight = video.videoWidth / aspectRatio;
    } else {
        newWidth = video.videoHeight * aspectRatio;
        newHeight = video.videoHeight;
    }

    const x = (video.videoWidth - newWidth) / 2;
    const y = (video.videoHeight - newHeight) / 2;

    return {
        x: x,
        y: y,
        width: newWidth,
        height: newHeight
    };
}



export function getAssetFromApi(filename) {
    return `${environments.app.api.server}/uploads/${filename}`
}

export const useIsMounted = () => {
    const isMounted = React.useRef(false);
    React.useEffect(() => {
        isMounted.current = true;
        return () => (isMounted.current = false);
    }, []);
    return isMounted;
};



export const onNewLiveMessage = (data) => {
    console.log(data);

    notification.info({ 
        message: `${data.sender_name}`, 
        placement: "bottomRight", 
        icon: <Avatar src={data?.image} /> ,
        description: data.content
        
    
    })


}

export const isExternal = (url)=>{

    return url.indexOf("youtu") > -1

}

export const genrateAvatarImageLink = (term)=>{
    return `https://api.dicebear.com/9.x/bottts/svg?seed=${term}`
}
export const genrateInitialsImageLink = (term)=>{
    return `https://api.dicebear.com/9.x/initials/svg?seed=${term}`
}

/**
 * Determines the camera type (front, back, or other) based on the device label
 * @param {MediaDeviceInfo} videoInput - The video input device to analyze
 * @returns {string} The camera type ('front', 'back', or 'other')
 */
export function detectCameraType(videoInput) {
    // Normalize the label to lowercase for case-insensitive matching
    const label = videoInput.label.toLowerCase();
  
    // Common patterns for front and back cameras
    const frontCameraPatterns = [
      'front', 
      'facetime', 
      'user', 
      'selfie'
    ];
  
    const backCameraPatterns = [
      'back', 
      'rear', 
      'environment'
    ];
  
    // Check for front camera patterns
    if (frontCameraPatterns.some(pattern => label.includes(pattern))) {
      return 'Front Camera';
    }
  
    // Check for back camera patterns
    if (backCameraPatterns.some(pattern => label.includes(pattern))) {
      return 'Back Camera';
    }
  
    // If no specific patterns match, return 'other'
    return videoInput.label;
  }