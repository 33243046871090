import React, { useState, useEffect, useContext, useRef } from 'react'
import { Button, Icon, Popover, Tooltip, Alert, Modal, notification } from 'antd'

import env from '../../../../environments';
import * as _ from 'lodash'
import adapter from 'webrtc-adapter'
import { logger, _notification, canUseCamera, canUseScreenRecording, getVideoStreamSettings, scaleToFit, useIsMounted, canIUse, onNewLiveMessage } from '../../../../utils';

import { EVT_RELAY_FAILED, EVT_LIVESTREAM_ENDED, EVT_LIVESTREAM_STARTED, EVT_RECORDING_ENDED, EVT_RECORDING_STARTED, EVT_START_RECORDING, EVT_STOP_LIVESTREAM, EVT_STOP_RECORDING, STREAM_TYPE_SCREEN, EVT_LIVECHAT_INCOMING, EVT_LIVESTREAM_INTERUPTED } from "../../../../constants";

import GlobalStateContext from '../../../../context';
import { isSafari, isIOS, fullBrowserVersion, isMobile } from "react-device-detect";
import AudioRecorder from 'audio-recorder-polyfill';
import UpgradeTiny from '../../../../components/UpgradeTiny';
// import CachedIcon from '@material-ui/icons/Cached';
// import { useWebRTC } from '../../../../hooks_simple_peer';
import { useWebRTC } from '../../../../hooks_openvidu';

import userIcon from "../../../../assets/images/user.png"
import userImage from '../../../../assets/images/user2.png'

import logo from "../../../../assets/images/logo_transparent.png"
import MediaPannel from './MediaPannel';
import MediaPanelOverlay from './MediaPanelOverlay';
import environments from '../../../../environments';
import Loading from '../../../../components/Loading';
import LoadingSmallCards from '../../../../components/LoadingSmallCards';
import { BsCameraVideo, BsCameraVideoOff, BsMic, BsMicMute, BsRecordCircle, BsRecordCircleFill } from "react-icons/bs";
import { TbScreenShare, TbScreenShareOff } from "react-icons/tb";

import MediaConfig from '../../../../components/MediaConfig';
import AudioVisualizerHtml from './audioVisualizer/AudioVisualizerHtml';
import Rater from '../../../../components/Rater';
import ConnectDestinations from './ConnectDestinations';
import InteruptionConfirmer from '../../../../components/InteruptionConfirmer';
import { StreamHeaderInfo } from '../ViewStreamPage';
import { ShareSocial } from 'react-share-social';
import { TvMinimal, Video } from 'lucide-react';
import { SET_ITEM } from '../../../../store/actions/types';
// import Title from 'antd/lib/typography/Title';


/**
 * 
 * @param {Object} props 
 * @param {object} props.constraints 
 * @param {function} props.onSuccess 
 * @param {function} props.onFail
 * @param {function} props.onStop
 * @param {string} props.sourceType  
 * @param {number} props.height  
 * @param {number} props.width  
 * @param {string} props.mimeType  
 * @param {string} props.streamToken  
 */

const fallbackContraints = {
    video: {
        deviceId: "",
        width: { ideal: 1920, max: 1920 },
        // height: { min: 576, ideal: 720, max: 1080 },
        // facingMode: "user",

    },

    audio: {
        // deviceId: "",
        echoCancellation: true,
        noiseSuppression: true
    },

    // audio: true


}


// var mediaStream;
var mediaStreamTracks;
var mediaStreamsLog = [];
var mediaRecorder;
var videoInputDevices = [];
var audioInputDevices = [];
var audioOutputDevices = [];
const shareButtonStyle = {
    root: {
        // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        background: 'rgba(59, 130, 246, var(--tw-bg-opacity))',
        borderRadius: 3,
        border: 0,
        // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        padding: "0",
        maxWidth: "100%"

    },
    copyContainer: {
        border: '1px solid blue',
        background: 'rgb(0,0,0,0.7)'
    },
    title: {
        color: 'white',
        fontStyle: 'italic'
    },
    copyBtn: {
        color: 'white',
    }

};


const StreamPreviewComp = (props) => {
    const { stream, onRemove, onSwitch, onMute, label, muted, visibile, onView, isModerator, isstaged, onStage, producerId, id, viduStream } = props
    const [src, setSrc] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const ref = useRef()
    const width = 100
    const height = 1.7 / 3 * width

    useEffect(() => {
        if (ref.current) {

            ref.current.srcObject = stream
        }

    }, [stream])
    useEffect(() => {
        setIsLoading(false)
    }, [isstaged])

    return (
        <div className='m-1' key={id}>
            <div className="stream-preview card-shadow" style={{ width, height }} onClick={_ => _}>
                {viduStream.videoActive ? <video ref={ref} autoPlay style={{ width, height }} muted={true} /> :
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', padding: '10px' }}>

                        <img src={userImage} alt="User Icon" style={{ width: '40%' }} />
                    </div>
                }
                <div className="filter"></div>
                <div className="actions">
                    {/* <Button size="small" onClick={_ => onView(label)} icon="eye" type={!visibile ? "default" : "primary"} /> */}
                    <Button size="small" onClick={_ => onMute(id)} icon="audio" type={!muted ? "default" : "primary"} />
                    <Button size="small" onClick={onRemove} icon="close" />
                </div>
                <div className='label'>{label}</div>
            </div>

            {
                isModerator &&
                <Button block type={isstaged ? "dark" : 'default'}
                    icon={isstaged ? "vertical-align-bottom" : "vertical-align-top"}
                    onClick={_ => {
                        setIsLoading(true);
                        // onStage(stream.id)
                        console.log("Staging:", producerId)
                        onStage(producerId)
                        setIsLoading(false);
                    }}
                    loading={isLoading}
                >
                    {isstaged ? "hide" : "show"}
                </Button>
            }
        </div>
    )

}
const PendingRequest = (props) => {
    const { onAccept, onDeny, request } = props
    const width = 100
    const height = 1.7 / 3 * width
    return (
        <div className='m-1' key={request.from}>
            <div className="stream-preview card-shadow" style={{ width, height }}>
                <video muted autoPlay style={{ width, height }} />
                <div className="filter"></div>
                <div className="actions">
                    {/* <Button size="small" onClick={_ => onView(label)} icon="eye" type={!visibile ? "default" : "primary"} /> */}

                </div>
                <div className='label'>{request.name}</div>
            </div>
            <Button.Group>

                <Button size='small' type={"warning"}
                    icon={"stop"}
                    onClick={onDeny}

                >

                </Button>

                <Button size='small' type={'success'}
                    icon={"check"}
                    onClick={onAccept}

                >

                </Button>
            </Button.Group>

        </div>
    )

}


const VideoItem = (props) => {
    const { stream, onRemove, onSwitch, onMute, label, muted, visibile, onView, autoPlay, _type, dimension } = props
    const [src, setSrc] = useState()

    const ref = useRef()
    const width = 100
    const height = 1.7 / 3 * width



    const iconMap = {
        camera: <Video size={20} />,
        screen: <TvMinimal size={20} />
    }

    useEffect(() => {
        ref.current.srcObject = stream
        console.log("Dimensions", dimension)

    }, [stream])

    return (

        <div className='mediastream' key={stream?.id}>
            <div className="overlay-top m-2 rounded-md">
                <div className='label  flex gap-2'>

                    <div>
                        {iconMap[_type.toLowerCase()]}

                    </div>
                    <div>

                        {label}
                    </div>
                </div>
            </div>
            <video id={props.id}
                ref={ref}
                playsInline
                className={`card-img-top video-screen bg-black ${dimension?.height > dimension?.width ? 'object-contain' : 'object-cover'}`}
                autoPlay={props.autoPlay || true}
                title={props.title}
                muted={muted}
                height={height}
                style={{
                    height: '100%',

                    // backgroundImage:`url(${userIcon})`
                }

                }
            // style={sourceType === "webcam" ? { transform: 'scaleX(-1)' } : null}
            >
                <source src={userIcon} type="image/png"></source>
            </video>
            <div className="overlay-bottom">

            </div>
        </div>

    )

}

const WebMediaStream = (props) => {
    let userVideoTag = useRef()

    let videoInputSelect = useRef()
    let audioInputSelect = useRef()
    const { onSuccess, onFail, onStop, sourceType, mimeType, height, width, mute, item, xActive, streamToken, brand, pup, name, onLiveStateChange } = props

    let DESTINATION = `${env.app.api.streamingServer.rtmp}/live/${props.streamToken}`;
    let MEDIAPROXYSERVER = env.app.api.streamingServer.webcamTranscoder
    // let COMPLETEURL = MEDIAPROXYSERVER + '?dest=' + encodeURIComponent(DESTINATION)
    let COMPLETEURL = MEDIAPROXYSERVER + '?strm=' + props.streamToken
    let FPS = 30;
    let BITRATE = 30;

    const switches = { false: 'off', true: 'on' }
    const [state, dispatch] = useContext(GlobalStateContext)
    const { requests,
        requestAccepted,
        remoteStreams,
        localStreams,
        rtcSetLocalStreams,
        roomid,
        names,
        setName,
        eventEnded,
        myId,
        requestToJoin,
        leave,
        setRoomId,
        acceptRequest,
        denyRequest,
        streamPeerMap,
        streamState,
        setStreamState,
        waitingApproval,
        requestToDisable,
        shareScreen,
        shadowGuy
    } = useWebRTC({ roomid: (item.id || item._id), isModerator: item.user == state.user._id, event: item, name, screenShareCallback: onToggleScreenshareCallback });


    const [constraints, setConstraints] = useState(props.constraints);
    const [isLoading, setIsLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [liveIsProcessing, setLiveIsProcessing] = useState(false);
    const [isLive, setIsLive] = useState(!!item.is_streaming);
    const [isRecording, setIsRecording] = useState(!!item.is_recording);
    const [config, setConfig] = useState({ mute: false, audio: { enabled: true }, video: { enabled: true } });
    const [canUse, setCanUse] = useState({ webcam: false, [STREAM_TYPE_SCREEN]: false });


    const [sharingScreen, setSharingScreen] = useState(false);
    const [sharingScreenLoading, setSharingScreenLoading] = useState(false);

    const [loadingRecording, setLoadingRecording] = useState(false);
    const [mediaStream, setMediaStream] = useState(null);
    const [allMediaStreams, setAllMediaStreams] = useState([]);
    const [message, setMessage] = useState();
    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const [showRater, setShowRater] = useState(false);
    const [showConfirmStop, setConfirmStop] = useState(false);
    const [enableGrid, setEnableGrid] = useState(streamState?.stream?.length);
    const [gridRows, setGridRows] = useState(1);
    const { io, features_accessible, active_streams, user, mediaPanel } = state;
    const [isModerator, setIsModerator] = useState(item.user == (user?._id || user?.id));
    // const [streamState, setStreamState] = useState(streamState)



    const isMounted = useIsMounted()

    const liveLoading = ((liveIsProcessing && (active_streams.indexOf(item.id) < 0)) ||
        (!liveIsProcessing && (active_streams.indexOf(item.id) > -1)));

    useEffect(() => {
        initialize();
        setCanUse({
            screen_recording: isModerator ? canUseScreenRecording(features_accessible) : true,
            webcam: isModerator ? canUseCamera(features_accessible) : true,
            live_recording: isModerator && !item.is_primary ? canIUse("live_recording", features_accessible) : false,
            live_stream: isModerator ? canIUse("live_stream", features_accessible) : false,
        })
            ;
        if (user) {
            setName(user.name);
        } else {
            setName(pup ? "__rcdr__" : name)
        }

        return async function () { !isMounted.current && await cleanUp() };

    }, []);

    useEffect(() => {
        console.log("liveIsProcessing: updated")
        dispatch({
            type: SET_ITEM,
            payload: ['header_actions', headerContent]
        })
    }, [liveIsProcessing, isRecording, isLive])








    useEffect(() => {


        // io.on(`${item.token}_active`, _ =>{

        //     setIsLive(true)

        // });

        io.on("updateStageState", data => {
            console.log("[x] non mod streamsate", data)

            !isModerator && setStreamState(data);

        })

        /**
         * Listen to Live and Recording events
         */

        io.on(`${EVT_LIVESTREAM_STARTED}_${roomid}`, function () {
            setIsLive(true);
            setIsActive(true);
            setLiveIsProcessing(false);
            _notification.success("Livestream started 🔴")
        });

        io.on(`${EVT_LIVESTREAM_ENDED}_${roomid}`, function () {
            console.log("evt_end", EVT_LIVESTREAM_ENDED)
            setIsLive(false);
            setIsActive(false);
            setLiveIsProcessing(false);
            _notification.success("Livestream ended")

        });

        io.on(`${EVT_RECORDING_STARTED}_${roomid}`, function () {
            console.log("Recording stated ")
            setIsRecording(true);
            setLoadingRecording(false)
            _notification.success("Recording started 🟡")
        });

        io.on(`${EVT_RECORDING_ENDED}_${roomid}`, function (reason) {
            console.log("Recording ended ")
            setIsRecording(false);
            setLoadingRecording(false)
            _notification.success("Recording ended")
        });
        io.on(`${EVT_RELAY_FAILED}_${roomid}`, function () {
            console.log("Relay failed ")
            setIsRecording(false);
            setIsLive(false);
            setLiveIsProcessing(false);
            Modal.error({
                content: "Livestream and recording are currently unavailable. Please try later."
            })
        });

        io.on(`${EVT_LIVECHAT_INCOMING}_${item.id}`, onNewLiveMessage)

        io.on(`${item.token}_done_publish`, () => {
            setShowRater(true)
        })
        io.on(`${EVT_LIVESTREAM_INTERUPTED}_${item.id}`, () => {
            setConfirmStop(true)
        })


    }, []);


    useEffect(() => {

        if (!!mediaStream) {

            console.log("Local media stream", mediaStream)

            const id = mediaStream.id;

            addStream(id, mediaStream);

            // setIsLoading(false);

            setConfig({ ...config, ['webcam']: id })
        }

        dispatch({
            type: SET_ITEM,
            payload: ['header_actions', headerContent]
        })


    }, [mediaStream]);

    useEffect(() => {
        onLiveStateChange && onLiveStateChange(isLive)
    }, [isLive])
    useEffect(() => {

        let filtered = Object.entries(remoteStreams).filter(([key, value]) => {
            const data = JSON.parse(value?.connection?.data)
            return data?.producer?.name !== shadowGuy;
        })

        filtered = Object.fromEntries(filtered)
        // setAllMediaStreams({ ...localStreams, ...remoteStreams })
        console.log("remotesss", filtered, remoteStreams)
        setAllMediaStreams({ ...filtered })
    }, [remoteStreams])



    useEffect(() => {

        requests.forEach(req => {
            Modal.confirm(

                {
                    icon: false,
                    // title: 'Do you want to delete these items?',
                    content: `${req.name} is asking to join`,
                    onOk() { acceptRequest(req) },
                    onCancel() { denyRequest(req) },
                    cancelText: "Deny",
                    okText: "Approve"
                }
            )
        })
    }, [requests])

    // useEffect(() => {

    //     setStreamState({
    //         ...streamState,
    //         streams: [...stagedStreams]
    //     })

    // }, [stagedStreams])

    useEffect(() => {
        if (streamState) {

            setGridRows(streamState.streams?.length - 1)
        }

    }, [streamState])




    useEffect(() => {
        if (isModerator && !!streamState) {

            console.log("[x] onstreamstateupdate", allMediaStreams, streamState);

            //    if( streamState.streams ){

            //     streamState.streams = streamState.streams.filter(strid=> Object.keys(allMediaStreams).includes(strid))

            // }
            // setPinnedStreamIsActive(streamState?.streams?.indexOf(streamState?.pinnedStream) > -1  )
            const mediPanel = _.pick(streamState.mediaPanel, ["banner.active", "branding.active_backdrop", "branding.active_logo", "color"])
            const data = {
                ...streamState,
                mediaPanel: mediPanel
            }

            io.emit("stageStateUpdated", item.id || item._id, data)

        }
    }, [streamState])


    useEffect(() => {
        if (isModerator) {

            const newState = { ...streamState, mediaPanel: state.mediaPanel }
            console.log("[x] Media Panel updated", newState)
            setStreamState(newState)
        }
    }, [state.mediaPanel])



    function addStream(label, stream, options = null) {
        //update stack and  config 
        // setAllMediaStreams({ ...allMediaStreams, [label]: stream })

        // rtcSetLocalStreams({ [label]: stream })
        return stream;
    }


    function toggleMic(label = null) {
        console.log("[x] ON mute", label, allMediaStreams)
        if (label) {

            const strm = allMediaStreams[label];

            console.log("[x] ON mute", label, strm)

            if (strm) {
                //Check if publish audio exist then current user is the stream owner
                if (strm.publishAudio) {

                    strm.audioActive ? strm.streamManager.publishAudio(false) : strm.streamManager.publishAudio(true)

                } else {
                    //request to mute
                    strm.audioActive && requestToDisable('audio', strm.connection)
                }

            }
            setAllMediaStreams({ ...allMediaStreams, [label]: strm })
        } else {
            //disable All audio
            toggleInput("audio")
        }
    }

    function toggleVideo(label = null) {
        if (label) {
            const strm = allMediaStreams[label];
            if (strm) {
                //Check if publish audio exist then current user is the stream owner
                if (strm.publishVideo) {

                    strm.videoActive ? strm.streamManager.publishVideo(false) : strm.streamManager.publishVideo(true)
                } else {
                    //request to mute

                    strm.videoActive && requestToDisable("video", [strm.connection])
                }

            }
        }
    }


    function stageStream(streamid = null) {
        console.log('streamState', streamState, allMediaStreams)
        var pinnedStream = streamState.pinnedStream;
        const isScreenstream = Object.values(allMediaStreams).find(strm => strm.typeOfVideo == "SCREEN" && strm.streamId == streamid)

        if (streamid) {
            const index = streamState?.streams?.indexOf(streamid);
            if (index > -1) {

                pinnedStream = isScreenstream ? null : pinnedStream;

                streamState.streams.splice(index, 1);
                setEnableGrid(!!pinnedStream && streamState.streams.length > 1);
                setStreamState({ ...streamState, pinnedStream })

            } else {

                const _curr = streamState.streams || []

                if (_curr.length >= 3) {
                    notification.warning({
                        placement: "topLeft",
                        description: "Maximum number of streams allowed on stage cannot exceed 3 ",
                        message: "Stage Limit"
                    },);


                    return;
                }

                pinnedStream = isScreenstream ? isScreenstream.streamId : pinnedStream;

                setEnableGrid(!!pinnedStream && streamState?.streams?.length > 0);
                setStreamState({
                    ...streamState,
                    streams: [..._curr, streamid],
                    pinnedStream
                }

                )

            }
        }
    }

 

    function removeStream(label) {
        const strm = allMediaStreams[label];
        if (strm) {
            strm.getTracks().map(trck => trck.stop());
            delete allMediaStreams[label];
            setAllMediaStreams({ ...allMediaStreams })
            setConfig({ ...config, [label]: false })
            // return merger.removeStream(strm)

            return strm
        }
    }


    const onGoLive = () => {
        setLiveIsProcessing(true);

        if (isLive) {
            console.log("Ending Livestream");
            io.emit(EVT_STOP_LIVESTREAM, { roomid });

        } else {

            console.log("Going live...");
            io.emit('start_livestream', { roomid });

        }

    }
    const onRecord = () => {
        setLoadingRecording(true);

        if (isRecording) {
            console.log("Ending Recording...");
            io.emit(EVT_STOP_RECORDING, { roomid });


        } else {

            console.log("Start Recording...");
            io.emit(EVT_START_RECORDING, { roomid });

        }

    }



    const unregisterSocketEvents = () => {
        io.off("updateStageState")
        io.off(`${EVT_LIVESTREAM_STARTED}_${roomid}`);

        io.off(`${EVT_LIVESTREAM_ENDED}_${roomid}`);

        io.off(`${EVT_RECORDING_STARTED}_${roomid}`);

        io.off(`${EVT_RECORDING_ENDED}_${roomid}`);
        io.off(`${EVT_LIVECHAT_INCOMING}_${item.id}`);
    }

    const cleanUp = async () => {
        console.log("Clean Me")
        stopStream(true);
        unregisterSocketEvents()
        leave()

    }



    function onToggleScreenshareCallback(data) {

        setSharingScreen(data?.status)
        setSharingScreenLoading(false)
        data?.message && _notification.info(data?.message)

    }

    function toggleShareScreen() {
        console.log("screen share", sharingScreen)
        setSharingScreenLoading(true)
        shareScreen(!sharingScreen, onToggleScreenshareCallback)


    }

    function toggleInput(type) {

        try {

            const currentState = config[type]?.enabled
            const streams = Object.values(allMediaStreams)
            for (let stream of streams) {
                console.log("is timp", stream.streamManager?.accessAllowed)
                if (stream.streamManager?.accessAllowed) {
                    console.log("is allowed", type)
                    type == 'video' ? stream.streamManager.publishVideo(!currentState) : stream.streamManager.publishAudio(!currentState)
                }
            }

            const newState = !currentState;

            setConfig({ ...config, [type]: { ...config[type], enabled: newState } })

        } catch (error) {
            logger.error(error)
            console.log(error)
            _notification.error(`Failed to toggle ${type}. Please refresh`)
        }

    }


    const onShowAddUser = () => {
        Modal.confirm({
            icon: false,
            content: <>
                Share with participants

                <ShareSocial
                    socialTypes={["whatsapp", 'facebook', 'twitter', 'email']}

                    style={shareButtonStyle}
                    url={`${environments.app.url}/${item.id}`}
                />

            </>
        })
    }



    function getSupportedMimeType() {
        var types = [
            "video/webm;codecs=h264",
            "video/mp4",
            "video/webm;codecs=opus,vp8",
            "video/mpeg",
            "video/webm;codecs=daala",
            "video/webm",
            "audio/mpeg",
            "audio/webm",
        ];

        for (var i in types) {

            if (MediaRecorder.isTypeSupported(types[i])) {
                return types[i];
            }
        }
    }

    function onGetUserMediaSuccess(stream) {
        try {

            console.log("DD:: Media stream success", stream);
            // merger.result;
            mediaStreamsLog.forEach(_stream => {
                _stream.getTracks().forEach(track => {
                    track.stop()
                })
            })

            mediaStreamsLog.push(stream);
            setMediaStream(stream);


            // addStream('webcam', stream)
            setIsLoading(false);
            return stream
        } catch (err) {
            // console.log(err);
            setIsActive(false);
            setIsLoading(false);
        };
    }
    const onGetUserMediaFailure = (error) => {
        setIsLoading(false);
        console.log("ssdsd", error)
        // console.log("Failed to get user media", error);
    }

    const _getUserMedia = (constraints, callback = null) => {
        try {


            if (navigator.mediaDevices && ("getUserMedia" in navigator.mediaDevices)) {

                navigator.mediaDevices.getUserMedia(constraints)
                    .then(callback || onGetUserMediaSuccess)
                    .catch(onGetUserMediaFailure);

            } else {

                navigator.getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);
                navigator.getUserMedia(constraints,
                    callback || onGetUserMediaSuccess,
                    onGetUserMediaFailure)
            }
        } catch (error) {
            console.log(error, "here2", navigator.getUserMedia, navigator.mediaDevices, navigator.webkitGetUserMedia);
            stopStream()
            _notification.error("Failed to get access to media stream devices.")

        }


    }
    const _getFakeMedia = (constraints) => {
        try {


            const canvas = document.createElement('canvas');
            const stream = canvas.captureStream();

            onGetUserMediaSuccess(stream)


        } catch (error) {
            console.log(error, "here2", navigator.getUserMedia, navigator.mediaDevices, navigator.webkitGetUserMedia);
            stopStream()
            _notification.error("Failed to get access to media stream devices.")

        }


    }

    const _getDisplayMedia = (constraints, callback = null) => {
        // Polyfill in Firefox.
        // See https://blog.mozilla.org/webrtc/getdisplaymedia-now-available-in-adapter-js/
        if (adapter.browserDetails.browser === 'firefox') {
            adapter.browserShim.shimGetDisplayMedia(window, 'screen');
        }

        if (navigator.mediaDevices && "getDisplayMedia" in navigator.mediaDevices) {
            navigator.mediaDevices.getDisplayMedia({ audio: constraints.audio, video: {} })
                .then(callback || onGetUserMediaSuccess)
                .catch(onGetUserMediaFailure);

        } else {
            // setSharingScreen(false)
            // navigator.getVRDisplays(constraints,
            //     callback || onGetUserMediaSuccess,
            //     onGetUserMediaFailure)
        }


    }



    /**
    * 
    * @param {*} constraints 
    */
    function startMediaStream() {

        try {
            /**
             * Here i will check if the incoming source type is a webcam or screen capture and then the appropraite
             * media device
             */
            return pup ?
                _getFakeMedia()
                : _getUserMedia(constraints)

        } catch (err) {
            props.onFail && props.onFail(err.message)
            return null
        };


    }



    const stopStream = (callOnStop = true) => {
        console.log("DD::Stopping stream");

        if (callOnStop === true) {
            if (!!props.onStop) {
                props.onStop()
            }
        }
        setIsActive(false);

        mediaStreamsLog.forEach(stream => {
            console.log("[x] About to stop tracks");
            stream.getTracks().forEach(track => {
                console.log(`[x] Stopping track ${track.id}`);
                track.stop()
                console.log(`[x] Track track ${track.id} stopped`);
            })
        })

        setIsLoading(false);
    }

    const headerContent = (<>
        <li className='flex flex-row gap-1'>
            {canUse["live_recording"] &&
                <button
                    className={`px-3 py-3 rounded-md font-medium transition-colors
                            ${isRecording
                            ? 'bg-red-100 text-red-600 hover:bg-red-200'
                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                        }`}

                    size="large"
                    block

                    type={isRecording ? "warning" : ""}
                    loading={loadingRecording}
                    disabled={loadingRecording}
                    onClick={onRecord}>

                    {loadingRecording ? null : isRecording ? "Stop Recording" : "Record"}

                </button>}
            {canUse['live_stream'] &&
                <button
                    className={`px-3 py-3 rounded-md font-medium text-white transition-colors
                            ${isLive
                            ? 'bg-red-500 hover:bg-red-600'
                            : 'bg-red-500 hover:bg-red-600'
                        }`}
                    // shape="round"
                    size="large"
                    block
                    disabled={liveIsProcessing}
                    // style={{ maxWidth: '10em' }}
                    // icon={isActive ? "poweroff" : 'fire'}
                    type="danger"
                    onClick={onGoLive}>
                    {/* {liveIsProcessing ? null : isLive ? <BsBroadcast className='margin-auto' size={20} /> : <BsBroadcastPin className='margin-auto' size={20} />} */}
                    {liveIsProcessing ? <Icon type="loading" /> : isLive ? "End Livestream" : "Start Livestream"}



                </button>
            }
        </li></>)

    const initialize = () => {
        logger.debug("@INITIALIZE");
        setIsLoading(true);
        try {
            startMediaStream();

        } catch (error) {
            logger.error(error);

            props.onFail && props.onFail(error);
            _notification.error("An error occured. Please try again");
            stopStream()
        }

    }

    return (
        <>

            {showRater && <Modal visible={showRater} footer={false} onCancel={_ => setShowRater(false)}>
                {showRater && <Rater item={item} callback={_ => setShowRater(false)} />}
            </Modal>}
            {showConfirmStop && <Modal visible={showConfirmStop} footer={false} onCancel={_ => setConfirmStop(false)}>
                {showConfirmStop && <InteruptionConfirmer item={item} callback={_ => showConfirmStop(false)} />}
            </Modal>}
            {waitingApproval ?
                <Loading text=" Waiting for approval." /> :
                (

                    <div className=" " style={{ height: '100%', paddingTop: "0px" }}  >
                        <div className='row m-0'>
                            <div className={`col-sm-12 ${isModerator ? "col-md-9" : "col-md-12"}`} >
                                {/* <div className=' col-sm-12  text-center'><StreamFeaturesAccessible /></div> */}
                                {isModerator &&
                                    <>


                                        <div className='flex justify-center m-2 rounded-lg'>

                                            <ConnectDestinations stream={item} />

                                        </div>
                                    </>

                                }
                                {/* <div className='brand-logo'>
            <img className='image' src={branding.logo.image} />
            <span className='text'>
                {branding.logo.text || item.title}
            </span>
        </div> */}
                                <div className={`border video-container ${pup ? 'pup' : ''}  aspect-w-16 aspect-h-7 `} style={{ backgroundImage: `url('${streamState?.mediaPanel?.branding?.active_backdrop}')` }}>

                                    <div className={` ${enableGrid ? " grid grid-cols-3  gap-4 " : ""} `}>
                                        {/* { isModerator && <span style={{margin:"auto",color:'#fff'}}> Your stage is empty. You can add banners or video on stage.</span>} */}

                                        {enableGrid &&

                                            <>

                                                <div className={`pinned col-span-2 content-center`}>
                                                    {



                                                        streamState?.streams?.filter(str => str == streamState?.pinnedStream).map((id, index) => {

                                                            const stream = allMediaStreams[id];
                                                            if (stream) {

                                                                const data = stream?.connection.data ? JSON.parse(stream?.connection.data) : {};
                                                                const isOwner = data.producer?.producerId == myId
                                                                const _type = "type"
                                                                console.log("checking on mute", stream)
                                                                const media = stream.getMediaStream()

                                                                return media && stream?.videoActive ? <VideoItem
                                                                    key={stream.streamId}
                                                                    id={item.id}
                                                                    playsInline
                                                                    autoPlay={true}
                                                                    title={props.title}
                                                                    muted={isOwner ? true : !stream?.audioActive}
                                                                    height={height}
                                                                    stream={media}
                                                                    style={{ height: '100%' }}
                                                                    label={data.producer.name}
                                                                    _type={stream?.typeOfVideo}
                                                                    dimension={stream?.videoDimensions}
                                                                /> :
                                                                    // <AudioVisualizer stream={stream} />
                                                                    <AudioVisualizerHtml key={stream?.streamId} mediaStream={media} label={data.producer.name} />
                                                            }
                                                        })

                                                    }

                                                </div>

                                                <div className={` mediastreams grid grid-flow-col grid-rows-${gridRows} gap-2  overflow-hidden `} style={{ width: "100%", height: "100%", overflow: "hidden" }} >


                                                    {

                                                        streamState?.streams?.filter(str => (str != streamState.pinnedStream) && !!allMediaStreams[str]).map((id, index) => {

                                                            const stream = allMediaStreams[id]
                                                            const data = JSON.parse(stream.connection.data)
                                                            const isOwner = data.producer?.producerId == myId
                                                            const _type = "type"
                                                            console.log("checking on mute", stream)
                                                            const media = stream.getMediaStream()

                                                            return media && stream.videoActive ? <VideoItem
                                                                key={stream.streamId}
                                                                id={item.id}
                                                                playsInline
                                                                autoPlay={true}
                                                                title={props.title}
                                                                muted={isOwner ? true : !stream.audioActive}
                                                                height={height}
                                                                stream={media}
                                                                style={{ height: '100%' }}
                                                                label={data.producer.name}
                                                                _type={stream.typeOfVideo}
                                                                dimension={stream?.videoDimensions}
                                                            /> :
                                                                // <AudioVisualizer stream={stream} />
                                                                <AudioVisualizerHtml key={stream.streamId} mediaStream={media} label={data.producer.name} />
                                                        })
                                                    }

                                                </div>

                                            </>

                                        }

                                        {
                                            !enableGrid && <div className={`c mediastreams  ${enableGrid ? " col-span-1 " : " "}  `}>


                                                {



                                                    streamState?.streams?.filter(str => !!allMediaStreams[str]).map((id, index) => {

                                                        const stream = allMediaStreams[id]
                                                        const data = JSON.parse(stream.connection.data)
                                                        const isOwner = data.producer?.producerId == myId
                                                        const _type = "type"
                                                        console.log("checking on mute", stream)
                                                        const media = stream.getMediaStream()

                                                        return media && stream.videoActive ? <VideoItem
                                                            key={stream.streamId}
                                                            id={item.id}
                                                            playsInline
                                                            autoPlay={true}
                                                            title={props.title}
                                                            muted={isOwner ? true : !stream.audioActive}
                                                            height={height}
                                                            stream={media}
                                                            style={{ height: '100%' }}
                                                            label={data.producer.name}
                                                            _type={stream.typeOfVideo}
                                                            dimension={stream?.videoDimensions}
                                                        /> :
                                                            // <AudioVisualizer stream={stream} />
                                                            <AudioVisualizerHtml key={stream.streamId} mediaStream={media} label={data.producer.name} />
                                                    })
                                                }


                                            </div>
                                        }



                                    </div>

                                    <MediaPanelOverlay mediaPanel={streamState?.mediaPanel} />

                                </div>


                                <div className='flex pt-2 max-w-2xl mx-auto'>

                                    {
                                        !pup &&
                                        <div className={`controls-container p-0 ${isModerator ? "col-12" : "col-12"}`} >
                                            <div className={`video-controls  bg-white border-b border-gray-200`} >

                                                <>
                                                    <div className="control bg-gray-100 p-2 rounded-lg">
                                                        <Popover content={
                                                            <div className="" >
                                                                <label>Webcam is <strong >{`${switches[config?.video?.enabled]}`}</strong> {!canUse['webcam'] && <UpgradeTiny />} </label>
                                                            </div>}>

                                                            <Button shape={"circle"} disabled={!canUse['webcam']} type={config.video.enabled != false ? "dark-gray" : "default"} size="large" onClick={e => toggleInput('video')}>

                                                                {config.video.enabled != false ? <BsCameraVideo size={20} /> : <BsCameraVideoOff size={20} />}

                                                            </Button>
                                                        </Popover>
                                                    </div>
                                                    <div className="control  bg-gray-100 p-2 rounded-lg" >
                                                        <Popover content={
                                                            <div className="" >
                                                                <label>Microphone is <strong >{`${switches[config?.audio?.enabled]}`}</strong> </label>
                                                            </div>}>

                                                            <Button shape="circle" type={config.audio.enabled ? "dark-gray" : "default"} size="large" onClick={_ => toggleInput('audio')} >

                                                                {config.audio.enabled ? <BsMic size={20} className='margin-auto' /> : <BsMicMute size={20} />}


                                                            </Button>

                                                        </Popover>

                                                    </div>
                                                    {isModerator && <div className="control  bg-gray-100 p-2 rounded-lg">
                                                        <Popover content={
                                                            <div className="" >
                                                                <label>Screen Sharing is <strong >{`${switches[!!sharingScreen]}`}</strong> </label>
                                                            </div>}>
                                                            <Button

                                                                shape={!canUse['screen_recording'] ? "round" : "circle"}
                                                                disabled={!canUse['screen_recording'] || sharingScreenLoading} type={sharingScreen ? "dark-gray" : "default"}
                                                                size="large"
                                                                onClick={toggleShareScreen}
                                                                loading={sharingScreenLoading}
                                                            >
                                                                {!sharingScreenLoading && <>

                                                                    {!canUse['screen_recording'] && <UpgradeTiny />}
                                                                    {sharingScreen ? <TbScreenShareOff size={20} className='margin-auto' /> : <TbScreenShare size={20} className='margin-auto' />}
                                                                </>
                                                                }
                                                            </Button>

                                                        </Popover>
                                                    </div>
                                                    }
                                                    {/* 
                                                    <div className="control  bg-gray-100 p-2 rounded-lg">
                                                        <Popover content={
                                                            <div className="" >
                                                                <label>Camera is <strong >{`${switches[config.video.enabled]}`}</strong> </label>
                                                                <select defaultValue={video.deviceId} id="videoInputDevice" ref={videoInputSelect} className="form-control" onChange={_ => onDeviceChange(_, "video")}>
                                                                    {availableDevices.videoInputDevices.map(device =>
                                                                        <option key={`video_input_option_${device.deviceId}`} value={device.deviceId}
                                                                        >{device.label}</option>
                                                                    )}
                                                                </select>
                                                            </div>}>
                                                        </Popover>
                                                    </div> */}


                                                    {
                                                        /*
                                                        <div className="control  bg-gray-100 p-2 rounded-lg" hidden={isActive}>
                                                            <Popover content={
                                                                <div className="form-group">
                                                                    <label>Audio output is <strong >{`${switches[!!audio]}`}</strong> </label>
            
                                                                </div>}>
                                                                <Button shape="circle" type={!config.mute ? "dark-gray" : "default"} size="large" onClick={_ => setConfig({ ...config, mute: !config.mute })} >
                                                                {!config.mute ? <HiOutlineSpeakerWave size={20} className='margin-auto' /> : <HiOutlineSpeakerXMark size={20} />}
                                                                </Button>
                                                            </Popover>
                                                        </div>
                                                        */
                                                    }

                                                </>

                                                {/* {
                                !isActive &&
                                <div className="control  bg-gray-100 p-2 rounded-lg">
                                    <Tooltip title={"Switch Camera"}
                                    >

                                        <Button
                                            icon="sync"
                                            shape="circle"
                                            size="large"
                                            onClick={switchCamera} />

                                    </Tooltip>
                                </div>
                            } */}
                                                <div className="control  bg-gray-100 p-2 rounded-lg" hidden={isActive}>
                                                    <Button shape="circle" icon="user-add" size="large" onClick={onShowAddUser} />
                                                </div>
                                                <div className="control  bg-gray-100 p-2 rounded-lg" hidden={isActive}>
                                                    <Button shape="circle" icon="more" size="large" hidden={isActive} onClick={_ => setShowMoreOptions(!showMoreOptions)} />
                                                </div>




                                            </div>

                                            <div className="avaible-video-streams">


                                                {

                                                    Object.keys(allMediaStreams).length > 0 ?




                                                        Object.values(allMediaStreams).map((val) => {

                                                            const viduStream = val
                                                            const i = viduStream.streamId

                                                            const info = JSON.parse(viduStream.connection.data)
                                                            const stream = viduStream.getMediaStream()


                                                            return (isModerator || info.producer?.producerId == myId) && <StreamPreviewComp
                                                                key={viduStream.streamId}
                                                                id={viduStream.streamId}
                                                                producerId={i}
                                                                label={info.producer.name}
                                                                stream={stream}
                                                                onRemove={_ => removeStream(i)}
                                                                // muted={auidoTrack && auidoTrack.enabled}
                                                                muted={true}
                                                                isstaged={streamState?.streams?.indexOf(i) > -1}
                                                                visibile={viduStream}
                                                                viduStream={viduStream}
                                                                onMute={toggleMic}
                                                                onView={toggleVideo}
                                                                onStage={stageStream}
                                                                isModerator={isModerator}
                                                            />


                                                        })
                                                        : <LoadingSmallCards alignment='row' number={3} />
                                                }

                                            </div>
                                            {/* <h3>{myId}</h3> */}
                                        </div>

                                    }
                                </div>
                            </div>
                            {
                                isModerator &&
                                <>
                                    <div className='media-pannel-container col-md-3 col-sm-12 bg-white'>
                                        <div className='my-2'>

                                            <StreamHeaderInfo item={item} />
                                        </div>
                                        <MediaPannel />
                                    </div>
                                </>

                            }
                        </div>


                        {message && <div className='row'>
                            {message}
                        </div>}

                        <Modal
                            title="Media Configuration"
                            visible={showMoreOptions}
                            footer={false}

                            onCancel={_ => setShowMoreOptions(false)}
                        >
                            <MediaConfig

                            />
                        </Modal>
                    </div>

                )}

        </>


    )
}

WebMediaStream.defaultProps = {
    constraints: fallbackContraints,
    sourceType: "webcam",
    preInfo: {},
    item: {},
    // height: "416px",
    // width: "740px",
    mute: true,
    mimeType: 'video/webm;codecs=h264',
    onSuccess: _ => null,
    onFail: _ => null,
    onStop: _ => null,
    brand: {
        logo: { image: logo },

    }
}

export default React.memo(WebMediaStream);