import React, { useState, useContext, useEffect } from 'react';
import { Form, Button, Input, InputNumber, Select, Tooltip, Icon, DatePicker, Switch, Collapse, Steps, notification } from 'antd';
import GlobalStateContext from '../../../../context'
import TextArea from 'antd/lib/input/TextArea';
import StreamWebService from '../../../../classes/StreamWebService';
import { withRouter } from 'react-router-dom';
import { protectedRoutesPrefix } from '../../../../routers/routes';
import Rider from '../../../../components/Rider';
import FileUpload from '../../../../components/FileUpload';
import { _notification, canIUse, canUserCreateStream } from '../../../../utils';
import { isMobile } from 'react-device-detect';
import SelectStreamSource from './SelectStreamSource';
import Greetings from '../../../../components/Greetings';
import FeatureNotAccessible from './FeatureNotAccessible';
import { STREAM_SOURCE_URL } from '../../../../constants';
import { v4 as uuid } from 'uuid';

const CreateStream = function (props) {

  const { onClose, type, item, onSave } = props;
  const { getFieldDecorator, setFieldsValue } = props.form;
  const [types, setTypes] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [isPublic, setIsPublic] = useState(!!item ? item.is_public : true);
  const [isLoading, setLoading] = useState(false);
  const [usdEquiv, setUSDEquiv] = useState(0.00);
  const [imageUploadState, setImageUploadState] = useState({});

  const [isDefaultCurreny, setisDefaultCurreny] = useState(true);
  const [state, dispatch] = useContext(GlobalStateContext);
  const { eventCategories, configuration, features_accessible, streams } = state;
  const { Option } = Select
  const [activeStep, setactiveStep] = useState(0)
  const [source, setSource] = useState(item?.source)
  const resource_id = item?.resource_id || uuid();

  const catOptions = eventCategories.map(d => <Option key={d}>{d}</Option>);

  const fetchTypes = async () => {
    try {
      const webservice = new StreamWebService();
      webservice.getStreamsTypes()
        .then(res => res.data)
        .then(types => setTypes(types));
    } catch (error) {
      _notification.error("Failed to fetch types");
    }
  }

  const featchDestinations = async () => {
    try {
      const webservice = new StreamWebService();
      webservice.getDestinations()
        .then(res => res.data)
        .then(dest => setDestinations(dest));
    } catch (error) {
      _notification.error("Failed to fetch destinations");
    }
  }


  const cleanUp = () => {
    onClose && (onClose());
    props.form.resetFields()
  }


  useEffect(() => {
    fetchTypes();
    featchDestinations();
    // setisDefaultCurreny(state.currency.code == "USD")
    return cleanUp;
  }, [])

  useEffect(() => {
    setUSDEquiv((item?.ppv_value * state.xrate) || 0.00)
    setisDefaultCurreny(state.currency.code == "USD")

  }, [item])
  useEffect(() => {
    setisDefaultCurreny(state.currency.code == "USD")
  }, [state.currency])

  const onFileUploadComplete = (info) => {
    console.log("onFileUploadComplete", info)
    setImageUploadState(info);
    setFieldsValue({ image: info.file.response.data.key })
  }

  const onSubmit = (e) => {
    e.preventDefault();

    props.form.validateFields(async (err, values) => {


      console.log(values);

      if (!err) {

        values.type = type;
        values.user = state.user.id;
        values.resource_id = resource_id;
        values.upload_data = { image: imageUploadState?.file?.response?.data }

        if (!isDefaultCurreny) {
          values.ppv_value = values.ppv_value ? parseFloat(values.ppv_value / state.xrate).toFixed(2) : 0.00;
        }
        setLoading(true);
        console.log(values);
        const service = new StreamWebService();
        await service
          .save({ data: values, id: item ? item.id : null })
          .then(resp => {
            props.form.resetFields();
            onClose && (onClose());
            // _notification.success("Stream saved successfully");
            //Navigate to the details page
            const dt = resp.data

            if (onSave) {
              console.log("is save")
              return onSave(dt)
            }
            const path = `/${protectedRoutesPrefix}/events`
            props.history.push(path)
          })
          .catch(err => {
            const msg = err.response ? err.response.data.message : err.message
            notification.error({ message: `Could not save. ${msg}` });
          }).finally(_ => {
            setLoading(false);
          });


      } else {
        console.log(Object.entries(err))
        Object.entries(err).forEach(error => error[1].errors.forEach(msg => { notification.error({ message: msg.message, placement: "topRight" }) }))
      }
    })
  }

  const onSourceSelect = (data) => {
    console.log(data)
    setactiveStep(activeStep + 1)
    setFieldsValue({ source: data })
    setSource(data)
  }

  const onStepSwitch = (step) => {
    setactiveStep(step)
  }


  const Step1 = (
    <div className='col-md-12 col-sm-12'>
      <Greetings title={"Video Source "} description="Where are you taking the video input from?" />

      <div className='m-t-20'>
        {/* Properly wrap the SelectStreamSource component within JSX */}
        {getFieldDecorator('source', {
          rules: [{ required: true, message: "Select a video source" }],
          initialValue: item?.source,
          validateTrigger: 'onBlur'
        })(
          <SelectStreamSource onSelect={onSourceSelect} stream={item} />
        )}
      </div>
    </div>
  );


  const Step2 = (
    <>



      <div className="mb-3" id="streamTitle">
        <label
          className="block text-gray-700 font-bold mb-2"
          htmlFor="eventTitle"
        >
          Event Title
        </label>
        <Form.Item
        >
          {getFieldDecorator('title', {
            rules: [{ required: true, min: 6, message: 'Title is required and must be at least 6 characters' }],
            initialValue: item ? item.title : "",
            validateTrigger: 'onBlur'
          })(<Input placeholder="Annual Music Festival and Awards" />)}
        </Form.Item>
      </div>
      <div className="mb-3" id="streamDescription">
        <label
          className="block text-gray-700 font-bold mb-2"
          htmlFor="eventTitle"
        >
          Description
        </label>
        <Form.Item className="strong">
          {getFieldDecorator('description', {
            initialValue: item ? item.description : "",
            rules: [{ required: true, min: 6, message: 'Please enter description and #tags' }],
          })(<TextArea placeholder=" A ceremony to the world! #worldfest #funtime " />)}
        </Form.Item>
      </div>

      {source !== STREAM_SOURCE_URL && <div className="  mb-3">

        <label
          className="block text-gray-700 font-bold mb-2"
          htmlFor="eventTitle"
        >
          <>
            Starts Date <small>(optional)</small>
          </>
        </label>
        <Form.Item  >
          {
            getFieldDecorator('starts_at', {
              defaultValue: !!item ? item.starts_at : null

            })(

              <DatePicker className='w-full' showTime={true} />

            )}
        </Form.Item>

      </div>}

      {
        source == STREAM_SOURCE_URL && (
          <div className=" mb-3" id="videoUrl">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="eventTitle"
            >
              URL
            </label>
            <Form.Item >
              {getFieldDecorator('url', {
                rules: [{ required: true, min: 6, message: 'External link. eg https://myvideourl.m3u8' }],
                initialValue: item ? item.url : "",
                validateTrigger: 'onBlur'
              })(<Input placeholder="https://myvideourl.m3u8" />)}
            </Form.Item>
          </div>
        )
      }
      <div className="mb-3" id="streamCategory">
        <label
          className="block text-gray-700 font-bold mb-2"
          htmlFor="eventTitle"
        >
          <>Category <small>(optional)</small></>
        </label>
        <Form.Item>
          {getFieldDecorator('category', {
            initialValue: item ? item.category : [],
            rules: [{ required: false, message: 'Specify category' }],
            // initialValue:"",
            validateTrigger: 'onBlur'
          })(
            <Select
            size="large"
              mode="tag"
              className='h-5'
              showSearch
              placeholder="football"
              defaultActiveFirstOption={false}
              showArrow={true}
              filterOption={true}
              // onSearch={this.handleSearch}
              // onChange={this.handleChange}
              notFoundContent={null}
            >
              {catOptions}
            </Select>
          )}
        </Form.Item>
      </div>
      <div className="" id="streamBanner">
        <label
          className="block text-gray-700 font-bold mb-2"
          htmlFor="eventTitle"
        >
          Cover Image
        </label>
        <Form.Item>
          {getFieldDecorator('image', {
            rules: [],
          })(
            <FileUpload
              data={{ resource_id: item?.resource_id || resource_id, bucket: "vod-viblica" }}
              text={'Event Image'}
              preview={true}
              onComplete={onFileUploadComplete}
            />
          )}
        </Form.Item>

      </div>
      <div className="flex flex-row" id="streamVisibility ">
        <div
          className="text-gray-700 font-bold mr-2"
          htmlFor="eventTitle"
        >
          Public
        </div>
        <div>
           <Form.Item>

          
          {getFieldDecorator('is_public', {
            checked: isPublic
          })(

            <Switch

              checkedChildren={<><Icon type="check" /> Yes</>}
              unCheckedChildren={<><Icon type="close" /> No</>}
              checked={isPublic}
              defaultChecked={isPublic}
              onChange={_ => setIsPublic(_)}
            />

          )}
           </Form.Item>
        </div>
      </div>
      {
        <Collapse bordered={false} >
          <Collapse.Panel key={1} header="Paywall">

            {(configuration.enable_ppv === '1' && canIUse('paywall', features_accessible)) &&

              <div className="">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="eventTitle"
                >
                  Paywall
                </label>
                <Form.Item

                                    help={`Amount you expect viewers to pay before watching your event.
                ${!isDefaultCurreny ?
                                        `Please note that this amount will be converted to USD at a rate of ${state.currency.symbol} ${state.xrate}` : ""

                                      }
                `}
                >
                  {getFieldDecorator('ppv_value', {
                    rulses: [{ min: 0.00, message: 'Amount cannot be lower than 0.00' }],

                    initialValue: !!item ? (!!item && item.ppv_value * state.xrate) : 0.00,
                    validateTrigger: 'onBlur'
                  })(<InputNumber min={0.00} placeholder={`${state.currency.symbol} 2.00`} onChange={valu => setUSDEquiv(parseFloat(valu / state.xrate).toFixed(2))} />)}
                  {
                    state.currency.code != "USD"
                    &&
                    <> &cong; <InputNumber disabled value={`$ ${usdEquiv} `} /></>
                  }
                </Form.Item>
              </div>
            }




          </Collapse.Panel>
        </Collapse>}


        <div className="steps-action">
          
            <Button size='large' block className='' style={{ marginLeft: 8 }} onClick={() => setactiveStep(activeStep - 1)}>
              Previous
            </Button>
  
            <Button block size='large' loading={isLoading} key="submit_button" disabled={isLoading} type="warning" onClick={onSubmit}>
              {item?.id ? "Update Event" : "Create Event"}
            </Button>
        
        </div>




    </>)



  const steps = [
    {
      title: "Source",
      content: Step1
    },
    {
      title: "Details",
      content: Step2
    }
  ]

  return (

    <>

      <Rider steps={state.introSteps.createStreamForm} />

      <div id="createStreamForm" className='flex flex-col' >
        {/* {!isMobile && <Steps type="navigation" onChange={onStepSwitch} current={activeStep} direction='horizontal'>
          {steps.map(step => {

            return <Steps.Step key={step.title} title={step.title} />
          }
          )}

        </Steps>} */}

        <div className="steps-content ">
          <Form layout="vertical" hideRequiredMark className='bg-white mb-4 w-full' >
            {
              steps.map((step, index) => (<div key={index} hidden={activeStep != index}> {step.content} </div>))
            }
          </Form>
        </div>


        <div className="m-t-20">
          {/* <Button key="cancel_button" onClick={onClose} style={{ marginRight: 8 }}>
          Cancel
            </Button> */}


        </div>

       


      </div >

    </>
  )
}

export default withRouter(Form.create()(CreateStream));