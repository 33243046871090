
import React, { useState, useEffect, useContext } from 'react'
import { Tag, message, Icon, Badge } from 'antd'
import env from '../environments';
import GlobalStateContext from '../context';
import Rider from './Rider';
import * as _ from 'lodash'
import PublicStreams from './PublicStreams';
import PaymentWebService from '../classes/PaymentWebService';
import WaitingForStream from '../pages/protected/streams/components/WaitingForStream';
import { STREAM_STATUS_DONE, EVT_RECORD_VIEW, PAYMENT_PAYSTACK, PAYMENT_FLUTTERWAVE, STREAM_STATUS_INACTIVE, STREAM_FILE_STATUS_READY, STREAM_STATUS_ACTIVE, STREAM_SOURCE_URL } from '../constants';
import FollowButton from './FollowButton';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import Loading from './Loading';
import { isSafari } from 'react-device-detect';
import { genrateAvatarImageLink, useQuery } from '../utils';
import Comments from './comment/Comments';
import { PayTicketFlow } from './PayTicketFlow';
import { Eye } from 'lucide-react';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';



// type VideoPartProps = { part:object, onClick:Function}
// type VideoPartsProps = { parts:object[],callback:Function}


export function VideoPart(props) {
    return (

        <div className='part' onClick={ev => props.onClick(props.part)}>
            <div className='part-thumbnail'></div>
            <div className='part-label'>{props.part.name}</div>
        </div>
    )
}




export function VideoParts(props) {

    return (
        <div className='video-parts'>

            {
                props.parts.length > 0 && props.parts.map(part => <VideoPart key={part.resource_id} part={part} onClick={props.callback} />)
            }


        </div>
    )
}

function SignalActive({ params }) {
    const { recordView, handleError, onBuffer, playbackUrl, snapshot } = params;




    return !isSafari ?

        <ReactPlayer
            className="aspect-w-16 aspect-h-9 player-content"
            playsinline
            playing
            controls
            onStart={recordView}
            onError={handleError}
            onBuffer={onBuffer}
            width="100%"
            loop
            // height="80vh"

            url={playbackUrl}
            style={{ maxHeight: "500px", background: "#000" }}
        />
        :
        <ReactPlayer

            className="aspect-w-16 aspect-h-9 player-content"
            playsinline
            playing
            controls
            onStart={recordView}
            onBuffer={onBuffer}
            width="100%"
            onError={handleError}
            loop
            // height="80vh"

            url={playbackUrl}
            style={{ maxHeight: "500px", background: "#000" }}
            config={{
                file: {
                    forceHLS: isSafari,
                    forceVideo: !isSafari,
                    hlsVersion: '0.12.4',
                    attributes: {
                        poster: snapshot,
                        disablePictureInPicture: true
                    }
                }
            }}
        />
}

function NoSignal({ item }) {


    return <WaitingForStream
        className="card-img-top   aspect-w-16 aspect-h-9 bg-gray-600 br-10 text-white"
        backdrop={item?.image}
        style={{ border: 'none' }}
        width={"none"}
        height={"none"}
        text={item.status == STREAM_STATUS_INACTIVE ? <div className='text-lg text-white'>Livestream not started</div> : <div className='text-lg text-white'>Event has Ended</div>}
        description={item.status == STREAM_STATUS_INACTIVE ? <div className='text-gray-400'>Video will be live when event starts</div> : <div className='text-gray-400'>You should have been here earlier</div>}
    />

}

export default function ViewPublicStream(props) {

    const { streamingServer } = env.app.api;
    const { live } = streamingServer.apps

    let { item, isEmbeded } = props;

    const [state, dispatch] = useContext(GlobalStateContext)
    const { io, user } = state;
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [shouldMakePayment, setShouldMakePayment] = useState(false);
    const id = item.id || item._id;
    const urlQuery = useQuery()
    const [playbackUrl, setPlaybackUrl] = useState(item.video)
    const [views, setViews] = useState(item.views)
    const [watching, setWatching] = useState(0)
    const [limitDescription, setLimitDescription] = useState(true)
    const selectedPart = urlQuery.get("part")
    const fallbackImageUrl = genrateAvatarImageLink(item?.user?.name)

    // const lnk = "http://localhost:8000/live/a80c60f5-49ad-4db8-8a3f-1bca1f866b0c/master.m3u8"

    const snapshot = item.image;


    const handleDonePublish = (streamToken) => {
        // message.info("Stream ended.")
    }
    const handleTermination = (reason) => {
        message.error(`Stream Terminated. ${reason}`)
    }

    const onViewCount = (count) => {
        setViews(count);
    }
    const onWachingCount = (count) => {
        setWatching(count);
    }
    const handleError = (err) => {
        setErrorMsg("Connection error. Check stream source if you entered the right destination url")
        setPlaybackUrl(`${playbackUrl}`)
    }
    const onBuffer = (err) => {
        setErrorMsg("[x] On Buffer")

    }

    const recordView = () => {

        // const webservice = new StreamWebService();
        // if (item.user) {
        //     if (user.id === item.user.id) {
        //         webservice.logView(id);
        //     }
        // } else {
        //     webservice.logView(id);
        // }
        io.emit(EVT_RECORD_VIEW, id, user.id);

    }

    const confirmPayment = () => {
        if (!user) {
            setShouldMakePayment(true)
        } else {

            const service = new PaymentWebService();
            service.confirm({
                entity: id,
                amount: item.ppv_value
            }).then(res => setShouldMakePayment(!res.data))
                .catch(reason => {
                    setShouldMakePayment(true)
                    message.info("You may be required to login")
                })
        }
    }


    const cleanUp = () => {
        io.emit(`leave_room`, `${item.id}.watching`);
        io.removeAllListeners(`${item.token}_done_publish`);
        io.removeAllListeners(`${item.token}_terminated`);
        io.removeAllListeners(`view_count_${item.id}`);
        io.removeAllListeners(`${item.id}.watching`);
    }


    useEffect(() => {
        console.log(`watching.${item.id}`, item.video)
        io.on(`${item.token}_done_publish`, handleDonePublish)
        io.on(`${item.token}_terminated`, handleTermination);
        io.on(`view_count_${item.id}`, onViewCount);
        io.on(`${item.id}.watching`, onWachingCount);
        io.emit(`join_room`, `${item.id}.watching`); //join watch room
        item.ppv_value > 0.00 && confirmPayment(); //confirm payment if the stream isn't free
        return cleanUp;

    }, [item.id]);

    useEffect(() => {
        if (selectedPart) {
            const target = item.parts.find(part => part.resource_id == selectedPart)
            if (target) {
                console.log(playbackUrl);
                setPlaybackUrl(target.path)
            }

        }

    }, [selectedPart])
    useEffect(() => {
        console.log(playbackUrl);


    }, [playbackUrl])


    const styles = {
        bodyContent: {
            display: "flex",
            justifyContent: "space-between"
        }
    }

    return (


        <Loading loading={isLoading}>
            <Rider steps={state.introSteps.viewStream} />
            <div className="row">
                {/* <div className="col-12 m-t-10">
                    <h4>{item.title} {item.status =="1" && <Badge status="error" />}</h4>
                </div> */}


            </div>
            <div className="row m-0 m-t-20">

                <div className="col-md-12 col-sm-12 col-lg-9  m-b-10">
                    <div className="card player-container  ">
                        {(shouldMakePayment && user._id != item.user._id) ?

                            <WaitingForStream
                                backdrop={item?.image}
                                className="card-img-top   aspect-w-16 aspect-h-9 bg-blue-100 br-10"
                                text={
                                    <>
                                        <p className="text-lg m-1"> Ticket required </p>

                                        {<div>

                                            <PayTicketFlow amount={item.ppv_value}
                                                payment_method={PAYMENT_PAYSTACK}
                                                entity={item}
                                                redirect_url={window.location}
                                                showButton={true} />

                                        </div>
                                        }
                                    </>
                                } />

                            :

                            ((item?.status == STREAM_STATUS_ACTIVE && item?.source == STREAM_SOURCE_URL) || item?.is_streaming == STREAM_STATUS_ACTIVE || item?.file_status == STREAM_FILE_STATUS_READY) ?
                                <SignalActive
                                    params={{ recordView, handleError, onBuffer, playbackUrl, snapshot }}
                                /> :
                                <NoSignal item={item} />

                        }
                        <div className='px-2'>

                            <div className="py-2">
                                <div className="flex flex-wrap items-center justify-between mb-2">
                                    <div className='flex flex-row'>
                                        <div>
                                        <object data={item?.user?.picture} type="image/jpeg" className="w-12 h-12 rounded-full bg-gray-100 m-2">
                      <img src={fallbackImageUrl} alt="Name" />
                    </object>
                                        </div>
                                        <div className='flex flex-col flex-wrap'>
                                        <div className="flex items-center gap-2">

                                            <h2 className="font-semibold text-lg sm:text-md">{item.title} </h2>
                                            {item.status == "1" && <div className="w-2 h-2 bg-red-500 rounded-full"></div>}

                                        </div>
                                        <Link to={`/channel/${item.user._id}`}>

                                            {item.user.name}
                                        </Link>
                                        <div className="text-xs">
                                            {moment(item.createdAt).fromNow(true)} ago
                                        </div>
                                    </div>
                                    </div>
                                    <div className="flex items-center gap-4">

                                        <div className='flex flex-col'>
                                            <div className="flex items-center gap-2 text-gray-600">
                                                <span className="text-sm">{views || 0} views</span>
                                            </div>

                                            {watching > 1 && <span className="text-xs">{watching || 0} watching</span>}

                                        </div>
                                        <div className="flex items-center gap-2">
                                            <FollowButton id={item.user._id} showCount={true} stream={item} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="py-2" hidden={isEmbeded}>

                                <div style={styles.bodyContent}>
                                    <div>
                                        <div>
                                            <Text>
                                                <em> {item.description.substring(0, limitDescription ? 32 : item.description.length)} </em>
                                                <a onClick={() => setLimitDescription(!limitDescription)}> {limitDescription ? '...more' : "...less"}</a>
                                            </Text>
                                        </div>
                                        <p>  <Tag color="cyan" >{item.category} </Tag></p>
                                    </div>
                                </div>
                            </div>
                            <div className='py-2'>
                                <VideoParts parts={item.parts} callback={objt => setPlaybackUrl(objt.path)} />
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap p-4 bg-gray-500 rounded-lg" hidden={isEmbeded}>

                        <PublicStreams title={' '} list_type='list' exceptions={[item._id]} />

                    </div>
                </div>
                <div className="col-md-12 col-sm-12 col-lg-3  m-b-10">
                    <div className='font-bold text-xl my-2'>  Comments </div>
                    <Comments streamId={item._id ?? item.id} />
                </div>



            </div>
            <div>
            </div>

        </Loading>
    )
}
