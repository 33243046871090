import { Avatar, Card, Checkbox, Dropdown, Icon, Menu, message, Modal } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React, { useEffect, useState } from 'react';
import recpng from "../assets/images/rec.png"
import DestinationWebservice from '../classes/DestinationWebservice';
import GetPlatformToken from './GetPlatformToken';


// type StatsProp = {
//     label: String,
//     value: Number,
//     channelImage: String | Object,
//     platformImage: String,
//     showOptions: Boolean,
//     item: Object,
//     user: Object,
//     callback: Function
// }




export default function ConfirmDestinationDelete({onConfirm,OnCancel,platform, channel,channelImage,platformImage}) {
    const [confirmDelete, setConfirmDelete] = useState(false);


  return (
    <Modal
    ok
    onOk={onConfirm}
    onCancel={OnCancel }
    visible={true} 
    title={<h6>Delete destination</h6>}
    okButtonProps={{ type: "danger", disabled: !confirmDelete }} 
    okText={`Delete Destination`}>
    <div className=' flex flex-row justify-center text-left m-1' style={{ display: "flex" }}>

        <Avatar shape='circle' size={60} src={channelImage || recpng} />
        <div className='m-1'>
            <h5 >{channel} </h5>
            {platformImage && <Avatar size={20} shape="circle" src={platformImage || ""} />}
            {platform}
        </div>
    </div>
    <div style={{ "fontSize": "1.5rem" }}>
        Confirm you want to delete this {platform} detination (<strong>{channel}</strong>) <br/>
        <small className='text-sm'>This will terminate any ongoing push to {channel}.</small>
        <div>
            <Checkbox onChange={(e) => setConfirmDelete(e.target.checked)}> Yes, go ahead.</Checkbox>
        </div>
    </div>

</Modal>
  )
}



export function DestinationCard(props) {
    const [showDeleteConfirmation, setShowDelConfirm] = useState(false);
    const [reconnect, setReconnect] = useState(false);


    const refreshDestination = (item) => {
        return setReconnect(true)

    }

    const ondeleteclick = () => setShowDelConfirm(true)
    const onCancel = () => setShowDelConfirm(false)

    const deleteDestination = () => {

        const wbs = new DestinationWebservice();
        wbs.deleteDestination(props.item.id)
            .then(_ => {
                message.success(`${props.value} deleted`);
                setShowDelConfirm(false);
                props.callback && props.callback();
            })
            .catch(_ => {
                message.success(`Failed to remove ${props.value}`);
            })

    }
    const menu = (item = {}) => {

        return <Menu>
            {
                item.auth &&
                <Menu.Item onClick={() => refreshDestination(props.item)}>

                    <Icon type='reload' /> Reconnect

                </Menu.Item>
            }
            <Menu.Item onClick={() => ondeleteclick(item)}>

                <Icon type='delete' /> Delete

            </Menu.Item>

        </Menu>
    }

    return <>

        {reconnect && <GetPlatformToken user={props.user} provider={props.item?.platform} destination_id={props.item?.id} trigger={true} callback={props.callback} />}

        <Card style={{ width: 300 }} className="stats-cardxxx max-w-2xl rounded-md">
            {props.item && <div style={{ "position": "relative", "float": "right", 'fontSize': "1.2rem" }}>
                <Dropdown overlay={menu(props.item)}>
                    <Icon type='down-circle' />
                </Dropdown>
            </div>
            }
            <Meta
                avatar={<Avatar shape='cicle' size={60} src={props.channelImage || recpng} />}
                title={props.value || "Value"}
                description={
                    <>
                        {props.platformImage && <Avatar size={30} shape="circle" src={props.platformImage || ""} />}
                        <span className='m-l-3'> {props.label && props.label}</span>

                    </>
                } />

            <Meta>
            </Meta>

        </Card>

        {showDeleteConfirmation && <ConfirmDestinationDelete OnCancel={onCancel}
             onConfirm={deleteDestination} 
             platform={props.label} 
             channel={props.value} 
             platformImage={props.platformImage}
             channelImage={props.channelImage}
             />}
    </>;
}




