import React, { useContext, useState, useEffect, useRef } from 'react'
import GlobalStateContext from '../../context'
import { Link, withRouter } from 'react-router-dom';
import environments from '../../environments';
import { List, Divider, Button, Icon, Input, Tag, Alert, Drawer } from 'antd';
import moment from 'moment';
import { useRavePayment } from "react-ravepayment";
import PaymentWebService from '../../classes/PaymentWebService';
import { PAYMENT_PAYSTACK, COUPON_TYPE_PERCENTAGE, COUPON_TYPE_ABSOLUTE } from '../../constants';
import { usePaystackPayment } from 'react-paystack';
import qs from "qs";
import LocalMoneyValue from '../LocalMoneyValue';
import { apprioprateVal, titleCase, _notification } from '../../utils';
import "../../assets/style/subscription.scss";
import CouponWebService from '../../classes/CouponWebService';
import { Animated } from 'react-animated-css';
import { isMobile } from 'react-device-detect';
import { uniqueId } from 'lodash';



export const FlutterwavePayment = ({
    currency = "USD",
    visible,
    onClose,
    onSuccess,
    txref,
    user,
    amount,
    pkg,
    description,
    title,
    redirect_url,
    meta = {},
    country
}) => {

    const config = {
        txref,
        customer_email: user.email,
        customer_phone: user.phone,
        amount: parseFloat(amount),
        PBFPubKey: environments.flutterwave.keys.public,
        production: environments.flutterwave.production,
        // redirect_url: redirect_url || '/app',
        currency,
        customer_firstname: user.name,
        custom_title: !!pkg ? 'Package Subscription' : title,
        custom_description: !!pkg ? `Subscribing to ${pkg.name} package` : description,
        custom_logo: '',
        subaccounts: [{}],
        payment_plan: pkg && pkg.external_service_id,
        meta: meta, country
    };
    const { initializePayment } = useRavePayment(config);
    useEffect(() => {

        if (visible) {
            initializePayment(onSuccess, onClose);
        }
    }, [visible])
    return (
        <div>
            { }
        </div>
    );
};


export const Paystack = ({ currency = "GHS", visible, onClose, onSuccess, txref, user, amount, pkg, description, title, redirect_url, meta = {}, country }) => {


    const config = {
        reference: txref,
        email: user.email,
        amount: Math.ceil(amount),
        currency,
        phone: user.phone,
        publicKey: environments.paystack.keys.public,
    };



    const initializePayment = usePaystackPayment(config);

    useEffect(() => {

        if (visible) {
            initializePayment(onSuccess, onClose)
        }
    }, [visible])

    return (
        <></>
    );
};



// type SubscribeButtonProps = {
//     text: String
// }

export const SubscribeButton = (props) => {
    const { location, text, payment_gateway, onCancel } = props;
    const [state] = useContext(GlobalStateContext);

    const { packages, user } = state;

    const [_loading, setLoading] = useState(false);

    const highestPackage = Math.max(...packages.map(pk => pk.price))

    var label = "Upgrade";

    var alreadySubscribed = !!user.active_subscription?._id;
    var isOnHighest = user.active_subscription?.package_price === highestPackage //check if its free then ask them to upgrade

    var elem = <Link className='btn btn-block btn-square btn-warning' to={"/pricing"}

        style={{ borderRadius: "0px" }}
    >{text || label}</Link>;

    if (alreadySubscribed && !isOnHighest) {
        elem = null
        // elem = <Tag className="text-center"> {user.active_subscription.package_name} <div><Link to={"/pricing"} > <small>  {text || label}</small></Link></div></Tag>
    } else if (alreadySubscribed && isOnHighest) {
        elem = null
    }

    return elem;
}

/**
 *
 * @param {{text,location}}props
 * @returns {JSX.Element}
 * @constructor
 */
export function Subscribe(props) {
    const { location, text, payment_gateway, onCancel } = props;
    const txWebservice = new PaymentWebService();
    const [state] = useContext(GlobalStateContext);

    const detailElem = useRef(null)

    const { packages, currency, user, xrate, feature_types, userLocationData } = state;
    const [checkoutData, setCheckoutData] = useState(null);
    const [_loading, setLoading] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const [modalVisibility, setModalVisibility] = useState(false);
    const [incomingPackageId, setIncomingPackageId] = useState("");
    const [couponErrorMessage, setCouponErrorMessage] = useState(null);
    const [loadingCoupon, setLoadingCoupon] = useState(false);
    const [smallSpace, setSmallSpace] = useState(false);

    const [coupon, setCoupon] = useState({});

    const [selectedPackage, setSelectPackage] = useState({})

    const [payable, setPayable] = useState();

    const selectedStyle = { border: "3px solid #1890ff" }



    // const subscribeButton = () => {
    //     var label = '';
    //     if (!user.active_subscription._id) {
    //         // label = "Subscribe";
    //         label = "Upgrade"; //@TODO come back to correct this
    //     } else {
    //         if (user.active_subscription.package_price !== highestPackage) { //check if its free then ask them to upgrade
    //             label = "Upgrade";
    //         }
    //     }
    //     return (!!label ?
    //         <Button  {...props} type="success" onClick={_ => setModalVisibility(true)} >{text || label}</Button> : null)
    // }


    const onPaymentSuccess = (resp) => {

        //Submit payment to api
        setLoading(false);
        setShowPayment(false);
        closeModal();
        window.location.reload(false);
        return true;
    }

    const premiumPackages = (
        packages.filter(pk => pk.is_free !== '1')
    )
    const onPaymentClose = (resp) => {
        txWebservice.deleteTransaction(checkoutData.reference)
            .then(resp => console.log(resp));
        //Submit payment to api
        setLoading(false);
        setShowPayment(false);
        return true;
    }


    const expiresON = (amount, unit) => {
        return moment().add(amount, `${unit}`).calendar();
    }

    const closeModal = () => {
        setModalVisibility(false);
    }
    const _onCancel = () => {
        onCancel();
        closeModal();
    }

    const onClickPay = () => {
        setLoading(true)

        const dxPayload = {
            entity_id: selectedPackage.id,
            payment_method: PAYMENT_PAYSTACK,
            currency_code: currency.code,
            coupon: coupon.id || coupon._id
        }

        txWebservice.pay(dxPayload)
            .then(resp => resp.data)
            .then(resp => {
                setCheckoutData(resp);
                setShowPayment(true)
                window.location.href = resp.checkout_url
                return;
            })
            .catch(err => {
                setLoading(false);
                console.log(err)
                _notification.error("Failed. Please try later")
            })
    }
    useEffect(() => {

        const queryStringOBj = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (!!queryStringOBj.subscribe) {
            setIncomingPackageId(queryStringOBj.subscribe);
            setModalVisibility(true);
            var i = packages.length;
            while (i--) {
                const _pkg = packages[i]
                if (_pkg.id == queryStringOBj.subscribe) {
                    setSelectPackage(_pkg);
                    break;
                }
            }

        }



        // if(!!queryStringOBj.terminate){
        //     window.close()
        // }

    }, [user.active_subscription, packages])

    useEffect(() => {

        setPayable(selectedPackage.price);


    }, [selectedPackage])

    useEffect(() => {

        setSmallSpace(isMobile || !!selectedPackage.price)


    }, [])

    const buildPackageList = () => {
        return (
            <div

                className="flex flex-row md:flex-col gap-2 package-cards overflow-x-scroll no-scrollbar">
                {premiumPackages.map(pkg => (

                   
                <div className={` rounded stream shadow ${selectedPackage.ref_id === pkg.ref_id ? "bg-blue-100" : "bg-white"}`} style={selectedPackage.ref_id === pkg.ref_id ? selectedStyle : null} onClick={() => {
                    isMobile && detailElem.current.scrollIntoView()
                    setSelectPackage(pkg);
                    setCoupon({})
                }}>

                    <div className="card-body row ">
                        <div className={"col-12 text-center"}>
                            <Icon type="code-sandbox" style={{ fontSize: "60px", color: "rgb(59 130 246)" }} />
                            <div className="text-muted" >{pkg.name}</div>
                        </div>
                        <div className={"col-12 text-center"}>
                            <List.Item.Meta
                                title={

                                    <LocalMoneyValue value={pkg.price} showSymbol={true} />

                                }
                                description={
                                    <span className="text-muted">
                                        {pkg.duration} {pkg.duration > 1 ? `${pkg.frequency}s` : pkg.frequency}
                                    </span>
                                }
                            />


                        </div>

                    </div>
                </div >
                  


                ))}
            </div>)
    }


    const renderPackageFeatures = (pkg) => {

        if (Object.keys(pkg).length < 1) {
            return null
        }

        if (Object.keys(pkg.features).length > 0) {
            return Object.keys(pkg.features).map(ft => {
                const detail = feature_types[ft];
                const _key = uniqueId()

                return (
                    < div key={_key} >
                        <div className="mb-2">
                            <span className="title">{titleCase(ft)} </span>
                            <span className="pull-right value">
                                {detail && detail?.type === "Boolean" ? ['NO', 'YES'][+ pkg.features[ft]] :
                                    pkg.features[ft] == '-1' ? 'Unlimited ' : apprioprateVal(detail?.dataType, pkg.features[ft])}
                                {detail && detail?.unit}
                            </span>
                        </div>
                        <Divider />
                    </div>
                )
            })
        }
    }
    const onCheckCoupon = (val) => {
        setLoadingCoupon(true);
        const service = new CouponWebService();
        let newAmount = selectedPackage.price;
        service.getCouponByCode(val)
            .then(response => {
                const { data } = response
                if (data.type === COUPON_TYPE_PERCENTAGE) {
                    newAmount = newAmount * (100 - data.value) / 100
                } else
                    if (data.type === COUPON_TYPE_ABSOLUTE) {
                        newAmount = newAmount - data.value
                    }
                setCoupon(data);
                setCouponErrorMessage(null)
                setPayable(newAmount);
            })
            .catch(error => {
                setPayable(selectedPackage.price);
                setCoupon({});
                setCouponErrorMessage(error.response ? error.response.data.message : error.message)
            }).finally(_ => setLoadingCoupon(false))
    }


    return (
        <div >


            <Drawer
                onClose={_onCancel}
                placement="bottom"
                height="100vh"
                width="100vw"
                title={<div className='text-center'> Package Subscription</div>}
                visible={modalVisibility}
                className="subscriptionModal p-0 md:p-2"
                onOk={onClickPay}

                style={{ backgroundColor: "#000" }}
                bodyStyle={{ background: "#eee", }}
            // bodyStyle={{ backgroundColor: "#000", color: '#fff' }}
            >
                
                <div className="flex flex-col md:flex-row gap-5 justify-center package-selection-detail m-0 p-0" >
                    {buildPackageList()}
                    <div className='bg-white rounded-lg p-3 w-full max-w-lg'
                    // className={!selectedPackage.id ? "col col-sm-12" : "col  col-md-12 col-sm-12" + " features-container bg-light p-5"}
                    >

                        <div ref={detailElem} hidden={Object.keys(selectedPackage).length < 1}>
                            {/* <h6>Package Detail</h6> */}
                            <div className="package-features">
                                {/* {renderPackageFeatures(selectedPackage)} */}
                            </div>
                            <div className="charges">

                                <div className="mb-2">
                                    <span> Will Expire On </span> <span className="pull-right">
                                        {expiresON(selectedPackage.duration, selectedPackage.frequency)}
                                    </span>
                                </div>
                                <Divider />
                                <div className="mb-2">
                                    <span>Base Amount</span> <span className="pull-right">
                                        <LocalMoneyValue value={selectedPackage.price} showSymbol={true} />
                                    </span>
                                </div>
                                <div className="mb-2" hidden={!coupon.value}>
                                    <Animated animationIn="fadeIn" animationOut="fadeOut">
                                        <span>Discount </span> <span className="pull-right">
                                            {coupon.type === COUPON_TYPE_ABSOLUTE
                                                ?
                                                <LocalMoneyValue value={coupon.value} showSymbol={true} />

                                                :
                                                `${coupon.value} % `

                                            }

                                        </span>
                                    </Animated>
                                </div>
                                <Divider />
                                <div className="mb-2">
                                    <span>Payable</span> <span className="pull-right">
                                        <LocalMoneyValue value={payable} showSymbol={true} /></span>
                                </div>
                            </div>
                            <div className="mb-2 m-t-2">
                                <h6>
                                    Discount Coupon Code  <Tag hidden={!coupon.user} >{coupon.user && coupon.user.name}</Tag>
                                </h6>
                                <Input.Search
                                    name="coupon" placeholder="AABBCCD" size="large"
                                    loading={loadingCoupon}
                                    onSearch={onCheckCoupon}
                                />
                            </div>
                            {couponErrorMessage &&
                                <div className="mb-2">
                                    <Animated animationIn="pulse" animationOut="fadeOut">
                                        <Alert message={couponErrorMessage} type="warning" />
                                    </Animated>
                                </div>
                            }

                        </div>




                        <div className="" style={{ bottom: "10px" }}>

                            <div className="m-t-20 m-t-20 flex flex-col"  >
                               
                                <button
                                    className="btn  bg-blue-500 text-white btn-block block"

                                    hidden={Object.keys(selectedPackage).length < 1}
                                    block=""
                                    onClick={onClickPay}
                                >
                                    Checkout  &nbsp; {<LocalMoneyValue value={payable} showSymbol={true} />}
                                </button>
                                <button
                                    className="btn w-24 mx-auto"

                                    hidden={Object.keys(selectedPackage).length < 1}
                                    block=""
                                    onClick={_onCancel}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>



                {

                    //    state.currency.code === "GHS" &&
                    //             <Paystack
                    //                 currency={state.currency.code}
                    //                 visible={showPayment}
                    //                 user={user}
                    //                 amount={(parseFloat(payable) * parseFloat(xrate)).toFixed(2) * 100}
                    //                 txref={txId}
                    //                 onClose={onPaymentClose}
                    //                 onSuccess={onPaymentSuccess}
                    //                 pkg={selectedPackage}
                    //                 counpon={coupon}
                    //             />
                }


                {
                    // state.currency.code !== "GHS" &&
                    //         <FlutterwavePayment
                    //             country ={userLocationData.country_code}
                    //             currency={currency.code}
                    //             visible={showPayment}
                    //             user={user}
                    //             amount={(parseFloat(payable) * parseFloat(xrate)).toFixed(2)}
                    //             txref={txId}
                    //             onClose={onPaymentClose}
                    //             onSuccess={onPaymentSuccess}
                    //             pkg={selectedPackage}
                    //             meta={[]}
                    //         /> 
                }


                {
                    showPayment && <iframe src={checkoutData?.checkout_url} frameborder="0" allowfullscreen style={{
                        width: "100vw", height: "100vh", position: 'absolute', top: 0,
                        background: '#fff', zIndex: "1000"
                    }} />
                }
            </Drawer>

        </div>
    )
}

export default withRouter(Subscribe);


Subscribe.defaultProps = {
    showModal: false,
    onCancel: _ => _,
}