import React, { useContext, useState, useEffect } from 'react';
import { Tag, Icon,  message, List, Avatar, Badge, Typography } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { protectedRoutesPrefix } from '../routers/routes';
import GlobalStateContext from '../context';
import env from '../environments';
import Paragraph from 'antd/lib/skeleton/Paragraph';
import LocalMoneyValue from './LocalMoneyValue';
import {buildCategory, buildCategoryHashtags} from '../utils';
import { STREAM_STATUS_INACTIVE } from '../constants';
import { StreamItemBase, StreamItemBasePreview } from './front/LiveEventsHero';

export default withRouter(function PublicStreamCard(props) {


    const [state, dispatch] = useContext(GlobalStateContext)
    const { io,user } = state;
    const { item, type } = props
    const [isLoading, setIsLoading] = useState(false);
    const [isActive, setActive] = useState(false);
    const { streamingServer } = env.app.api;
    const { live } = streamingServer.apps;
    const previewLink = `${streamingServer.websocket}${live}/${item.token}.flv`;
    const path = !!user ?  `/${protectedRoutesPrefix}/stream/${item.id}/watch` :`/${item.id}/watch`;
    const snapshot = item.image;

    const handleError = (err) => {
        console.log(err);
    }

    const cleanUp = () => {
        io.removeAllListeners(`${item.token}_active`);
        io.removeAllListeners(`${item.token}_done_publish`);
    }

    const _card = (
        <div key={item.id} className="stream  col-md-3 col-sm-12">
            <div className="card">
                <Link to={path} >
                <StreamItemBase item={item}/>
                </Link>

            </div>

        </div>
    )
    const _list = (

        <div key={item.id} className="my-2">
               
                   {/* <StreamItemBase item={item}/> */}
                   <StreamItemBasePreview item={item}/>
                  
       
           
        </div>
    )




    useEffect(() => {
        io.on(`${item.token}_active`, data => {
            message.info("active")
            setActive(true);
        })

        io.on(`${item.token}_done_publish`, data => {
            console.log("ot  nactive...")
            setActive(false);
        })

        if (item.status === '1') {
            setActive(true)
        }


        return cleanUp;


    }, [state.streams])

    return (
        type == 'list' ?
            _list : _card
    )


})
